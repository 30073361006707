<template>
  <div class="selectEvalsPagination">
    <PaginationControls
      v-model:selectedPageSize="pageSize"
      v-bind="listState"
      @firstPage="goToPage(1)"
      @previousPage="previousPage"
      @nextPage="nextPage"
      @lastPage="goToPage(listState.totalPages)"
      @goToPage="goToPage($event)"
    />
  </div>
</template>

<script>
import { PaginationControls } from 'vue-components';
import { mapState, mapActions } from 'pinia';
import { useEvalStore } from '@/stores';

export default {
  name: 'SelectEvalsPagination',
  components: {
    PaginationControls
  },
  inject: ['notify'],
  computed: {
    ...mapState(useEvalStore, { listState: 'evalListState' }),
    pageSize: {
      get() {
        return this.listState.pageSize;
      },
      set(value) {
        this.updatePageSize(value).catch(error => {
          this.notify.error(error);
        });
      }
    }
  },
  methods: {
    ...mapActions(useEvalStore, {
      updatePageSize: 'updatePageSize',
      fetchPreviousPage: 'previousPage',
      fetchNextPage: 'nextPage',
      goToPage: 'goToPage'
    }),
    previousPage() {
      this.fetchPreviousPage().catch(error => {
        this.notify.error(error);
      });
    },
    nextPage() {
      this.fetchNextPage().catch(error => {
        this.notify.error(error);
      });
    }
  }
};
</script>

<style lang="scss">
.selectEvalsPagination {
  position: relative;
  border-radius: 4px;
  box-shadow: var(--elevation-low);
  transition: 0.1s linear;
  padding: 8px 16px;
  background-color: var(--white);
}
</style>
