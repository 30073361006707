<template>
  <div class="comments">
    <Comment
      v-for="(comment, i) in sortedComments"
      :key="i"
      :comment="comment"
      :last="i === comments.length - 1"
      @edit="$emit('edit', comment)"
      @delete="$emit('delete', comment)"
      data-cy="comment"
    />
  </div>
</template>

<script>
import Comment from './Comment.vue';
import { convertPrettyTimeToSeconds } from '@/utils/time';

export default {
  // eslint-disable-next-line
  name: 'Comments',
  emits: ['edit', 'delete'],
  components: {
    Comment
  },
  props: {
    comments: Array
  },
  computed: {
    sortedComments() {
      return [...this.comments].sort((a, b) => {
        return convertPrettyTimeToSeconds(a.timeInMedia) - convertPrettyTimeToSeconds(b.timeInMedia);
      });
    }
  }
};
</script>

<style lang="scss">
.comments {
  padding: 16px 0 24px 0;
}
</style>
