<template>
  <BaseDialog v-model="show" v-bind="$attrs" :click-to-close="false">
    <template v-slot:title>Add a Comment</template>
    <template v-slot:content>
      <div class="commentForm__content">
        <div class="commentForm__timestamp">
          <label>Timestamp</label>
          <input v-model="comment.timeInMedia" type="text" size="6" data-cy="comment-timestamp-input" />
          <div>
            <i v-if="!timestampValid" class="icon error" :title="timestampTooltip">error_outline</i>
          </div>
          <div class="required">required</div>
        </div>
        <div>
          <label>Comment Title</label>
          <input v-model="comment.commentTitle" placeholder="Give your comment a title" data-cy="comment-title-input" />
        </div>
        <div class="commentForm__commentField">
          <label>
            <span>Comment</span>
            <span class="required">required</span>
          </label>
          <textarea
            v-model="comment.description"
            rows="5"
            placeholder="Type your comment here"
            data-cy="comment-description-input"
          ></textarea>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <TpgButton flat @click="cancel">Cancel</TpgButton>
      <TpgButton @click="submit" :disabled="!commentFormValid" data-cy="submit-comment-button">
        <i class="icon">post_add</i>{{ this.editMode ? 'Update' : 'Add' }}
      </TpgButton>
    </template>
  </BaseDialog>
</template>

<script>
import { BaseDialog, TpgButton } from 'vue-components';
import { validateTimeInput, convertPrettyTimeToSeconds, prettyTimeString } from '@/utils/time';

const initComment = () => ({
  commentTitle: '',
  description: '',
  timeInMedia: '0:00'
});

export default {
  name: 'CommentForm',
  components: {
    BaseDialog,
    TpgButton
  },
  data: () => ({
    show: false,
    editMode: false,
    comment: initComment()
  }),
  props: {
    /**
     * The maximum number of seconds that the user can input for a timestamp.
     * Determined by the length of active media.
     */
    maxSeconds: Number
  },
  emits: ['commentSubmitted', 'commentUpdated'],
  computed: {
    timestampValid() {
      return (
        validateTimeInput(this.comment.timeInMedia) &&
        (this.maxSeconds ? convertPrettyTimeToSeconds(this.comment.timeInMedia) <= this.maxSeconds : true)
      );
    },
    commentFormValid() {
      return !!this.comment.timeInMedia && this.timestampValid && !!this.comment.description;
    },
    timestampTooltip() {
      return `This timestamp is invalid. Please use 'hh:mm:ss' format.${
        this.maxSeconds ? ' Max timestamp for current media is ' + prettyTimeString(this.maxSeconds) + '.' : ''
      }`;
    }
  },
  methods: {
    newComment(init) {
      this.show = true;
      this.comment = { ...this.comment, ...init };
    },
    editComment(comment) {
      this.show = true;
      this.editMode = true;
      this.comment = { ...comment };
    },
    cancel() {
      this.show = false;
      this.reset();
    },
    submit() {
      this.$emit(this.editMode ? 'commentUpdated' : 'commentSubmitted', { ...this.comment });
      this.show = false;
      this.reset();
    },
    reset() {
      this.editMode = false;
      this.comment = initComment();
    }
  }
};
</script>

<style lang="scss">
.commentForm {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
    margin-bottom: 32px;

    & label {
      font-weight: var(--font-weight-bold);
      display: block;
      margin-bottom: 8px;
    }

    & textarea,
    & input {
      background-color: var(--primary-dark);
      border: none;
      border-radius: 4px;
      padding: 16px;
      font-family: var(--font-family-default);
      font-size: var(--font-size-normal);
      width: calc(100% - 32px);

      &::placeholder {
        color: #a4a4a4;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }

  &__timestamp {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto auto auto;
    column-gap: 16px;

    & label {
      margin-bottom: 0;
    }

    & input {
      width: min-content;
      text-align: center;
      padding: 8px 16px;
      font-weight: var(--font-weight-bold);
      color: var(--black-trans);
    }

    & i.icon.error {
      color: var(--error);
      cursor: default;
    }

    & .required {
      justify-self: flex-end;
    }
  }

  &__commentField {
    & label {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
