/**
 * Creates a new initialized assessment object to use as an active assessment.
 * @param {Object} agent The Agent to perform the assessment for.
 * @param {number} agentSourceId The user selected agent source id.
 * @param {number} programId The user select program id.
 * @param {string} memberName The member name of the logged in user (performing the assessment).
 */
export const newAssessment = (agent, agentSourceId, programId, memberName) => ({
  agent,
  agentId: agent.agentId,
  agentSourceId,
  programId,
  memberName,
  completedDate: null,
  startDateTime: new Date().toISOString(),
  endDateTime: null,
  comments: [],
  evaluations: [],
  agentTargets: [],
  enterpriseTargetIds: [],
  generalSkills: [],
  finalComment: '',
  coachingMethodIds: [],
  nextAssessmentDate: new Date()
});

/**
 * Formats a client side assessment to an appropriate structure for the API to save.
 * @param {Object} complete The completed active assessment.
 * @param {boolean} draft Whether or not to save this assessment as a draft.
 */
export const formatAssessmentForSave = (complete, draft = false) => {
  // Set completed and end date to now in ISO string format.
  complete.completedDate = new Date().toISOString();
  complete.endDateTime = new Date().toISOString();
  // Convert next assessment date to ISO string format.
  complete.nextAssessmentDate = complete.nextAssessmentDate.toISOString();
  // Delete the whole agent object as we have the individual params we need already.
  delete complete.agent;
  // Map agent targets to only include relevant props.
  complete.agentTargets = complete.agentTargets.map(({ compendiumBehaviorId }) => ({ compendiumBehaviorId }));
  // Map selected evaluations to desired props.
  complete.evaluations = complete.evaluations.map(e => {
    return {
      evaluationId: e.evaluationId,
      documentId: e.mediaDocumentId || 0
    };
  });
  // Delete the tempId from any evaluation comments added.
  complete.comments.length
    ? (complete.comments = complete.comments.map(c => {
        c.tempId ? delete c.tempId : undefined;
        return c;
      }))
    : undefined;

  complete.draft = draft;

  return complete;
};

/**
 * Maps a backend coaching session to the client activeAssessment structure so the user can continue coaching.
 * @param {Object} draft The draft coaching session from the back end.
 * @param {Object} agent The agent that this coaching session is for.
 * @param {Array} compendiumBehaviors The list of compendium behaviors for this agent.
 */
export const mapDraftToActive = (draft, agent, compendiumBehaviors) => {
  const active = {
    coachingSessionId: draft.coachingSessionId,
    agent,
    agentId: draft.agentId,
    agentSourceId: draft.agentSourceId,
    programId: draft.programId,
    memberName: draft.coachedBy,
    completedDate: null,
    startDateTime: new Date().toISOString(),
    endDateTime: null,
    comments: draft.coachingSessionComments,
    evaluations: draft.coachingSessionEvaluations.map(e => ({
      ...e.evaluation,
      ...e,
      mediaDocumentId: Number(e.recordingId)
    })),
    agentTargets: draft.coachingSessionBehaviors
      .filter(b => !!b.agentFocusBehavior)
      .map(b => ({
        ...b.agentFocusBehavior,
        behaviorText: b.agentFocusBehavior.compendiumBehaviorText,
        behaviorId: compendiumBehaviors.find(
          cb => cb.compendiumBehaviorId === b.agentFocusBehavior.compendiumBehaviorId
        ).behaviorId
      })),
    enterpriseTargetIds: draft.coachingSessionBehaviors
      .filter(b => !!b.siteFocusBehaviorId)
      .map(b => b.siteFocusBehaviorId),
    generalSkills: draft.coachingSessionBehaviors.filter(b => !!b.generalSkill).map(b => b.generalSkill),
    finalComment: draft.finalComment,
    coachingMethodIds: draft.coachingSessionMethods.map(m => m.coachingMethodId),
    nextAssessmentDate: new Date(draft.nextScheduledDate)
  };

  return active;
};
