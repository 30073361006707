import { defineStore } from 'pinia';
import { AuthService } from '@/services/AuthService';
import { LAUNCHPAD_URL } from '@/application.config';
import { getActivePinia } from 'pinia';
import * as jose from 'jose';

export const useAuthStore = defineStore('auth', {
  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    /**
     * Gets auth tokens from launchpad.
     * Logs out if tokens are null.
     */
    getTokens() {
      return AuthService.getTokens().then(creds => {
        if (!creds) {
          this.logout(encodeURIComponent(window.location.href));
        } else {
          return creds;
        }
      });
    },
    /**
     * Gets logged in user info from launchpad.
     */
    getLoggedInUser() {
      return AuthService.getLoggedInUser().then(user => {
        if (!user) {
          this.logout();
        } else {
          return user;
        }
      });
    },
    /**
     * Sends the user to launchpad to log out.
     * @param {string} returnUrl Encoded URI Component that the user would like to access.
     */
    logout(returnUrl) {
      // Reset state of every store.
      getActivePinia()._s.forEach(store => store.$reset());
      // Remove any local storage data.
      localStorage.clear();
      // Append return url to logout url if provided.
      const url = returnUrl ? `${LAUNCHPAD_URL}/logout?returnUrl=${returnUrl}` : `${LAUNCHPAD_URL}/logout`;
      // Redirect to launchpad logout page.
      location.assign(url);
    },
    /**
     * Checks if a user has the specified permission.
     * For example:
     *   if a user has permissions: { Coaching: [ 'admin' ] }
     *   then the call checkPermission('Coaching', 'admin') will return TRUE.
     * @param {string} category The category of permission to check for.
     * @param {string} level The level of access in the given category to check for.
     * @returns {Promise<boolean>}
     */
    checkPermission(category, level) {
      return this.getTokens().then(({ access_token }) => {
        if (access_token && category && level) {
          return jose.decodeJwt(access_token).permissions[category]?.includes(level) || false;
        } else {
          return false;
        }
      });
    }
  }
});
