<template>
  <div class="datepicker">
    <label v-if="label" class="datepicker__label">{{ label }}</label>
    <div class="datepicker__field" :class="{ openAbove: openAboveField }">
      <Datepicker
        v-model="value"
        class="datepicker__field--picker"
        :lowerLimit="lowerLimit"
        :inputFormat="inputFormat"
      />
      <div class="datepicker__field--iconWrapper">
        <i class="icon">date_range</i>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker';

export default {
  name: 'TpgDatepicker',
  components: {
    Datepicker
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: Date,
    label: String,
    lowerLimit: Date,
    inputFormat: String,
    openAboveField: Boolean
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  }
};
</script>

<style lang="scss">
.datepicker {
  &__label {
    display: block;
    font-weight: var(--font-weight-bold);
    color: var(--assess-text-color);
    margin-bottom: 8px;
  }

  &__field {
    display: inline-block;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    position: relative;

    &--picker {
      height: 39px;
      padding-left: 16px;
      min-width: 180px;
      border-radius: 4px;
      color: var(--assess-text-color);
      font-weight: var(--font-weight-bold);
      font-family: var(--font-family-default);
      border: none;
      background-color: var(--white);
      cursor: pointer;
    }

    & i.icon {
      position: absolute;
      right: 8px;
      top: 8px;
      cursor: pointer;
      pointer-events: none;
    }

    &--iconWrapper {
      color: var(--assess-text-color);
      background-color: var(--white);
    }
  }
}

.openAbove {
  // Custom override for vue3-datepicker.
  // Makes the picker appear above the date field. (this date field is at the bottom of the page.)
  & .v3dp__popout {
    bottom: 43px !important;
  }
}
</style>
