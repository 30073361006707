<template>
  <div class="trendCardLoading">
    <div class="rect top">TIME FRAME</div>
    <div class="rect middle">Metric</div>
    <div class="rect bottom">Trend description</div>
  </div>
</template>

<style lang="scss" scoped>
.trendCardLoading {
  background-color: var(--white);
  box-shadow: var(--elevation-low);
  padding: 32px 48px 40px 48px;
  border-radius: 6px;
  border: var(--border-subtle);
  display: flex;
  flex-direction: column;
}

.rect {
  width: fit-content;
  background: linear-gradient(to right, #ddd 20%, #eee 50%, #ddd 80%);
  animation: shiny-rect 4s linear infinite;
  color: transparent;

  &.top {
    height: 17px;
    margin-bottom: 24px;
  }

  &.middle {
    margin-bottom: 8px;
    height: 39px;
  }

  &.bottom {
    height: 20px;
  }
}

@keyframes shiny-rect {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
</style>
