export const MarkerTypes = {
  Danger: 'Danger',
  Warning: 'Warning',
  Nominal: 'Nominal',
  Info: 'Info'
};

/**
 * Returns marker type based off defect state
 * @param {boolean} isDefect
 * @returns Marker type
 */
export const getMarkerType = isDefect => {
  let markerType = MarkerTypes.Warning;

  if (isDefect == true) {
    markerType = MarkerTypes.Danger;
  } else if (isDefect === false) {
    markerType = MarkerTypes.Nominal;
  }

  return markerType;
};
