<template>
  <div class="page agentSource">
    <h1>Welcome to Develop</h1>
    <h5 class="agentSource__instruction">Please select a program to continue.</h5>
    <ProgramDropdownDataHarness />
    <TpgButton class="agentSource__continue" :disabled="!canContinue" @click="handleContinue" data-cy="continue-button">
      Continue<i class="icon">arrow_forward</i>
    </TpgButton>
    <div class="agentSource__graphic">
      <img :src="require('../assets/agentSourceGraphic.png')" />
    </div>
    <LavaLamp />
  </div>
</template>

<script>
import { TpgButton } from 'vue-components';
import { mapActions, mapState } from 'pinia';
import { useDocStore, useAgentStore } from '@/stores';
import { LavaLamp, ProgramDropdownDataHarness } from '@/components';

export default {
  name: 'AgentSourceSelection',
  components: {
    TpgButton,
    LavaLamp,
    ProgramDropdownDataHarness
  },
  computed: {
    ...mapState(useAgentStore, ['selectedAgentSourceId', 'selectedProgramId']),
    canContinue() {
      return this.selectedAgentSourceId && this.selectedProgramId;
    }
  },
  mounted() {
    this.updateDocTitle('Select Program');
  },
  methods: {
    ...mapActions(useDocStore, ['updateDocTitle']),
    handleContinue() {
      const encodedUrl = this.$route.query?.returnUrl;
      this.$router.push(encodedUrl ? decodeURIComponent(encodedUrl) : { name: 'ManagerDash' });
    }
  }
};
</script>

<style lang="scss">
.agentSource {
  position: relative;
  max-width: 60vw !important;
  padding-top: 80px !important;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--app-header-height) - 120px);
  gap: 24px;

  &__continue {
    width: fit-content;
  }

  &__graphic {
    position: absolute;
    bottom: 5%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;

    & img {
      max-height: 50vh;
      width: auto;
    }
  }
}

@media (max-width: 900px) {
  .agentSource {
    max-width: 80vw !important;
  }
}
</style>
