/**
 * Converts a number of seconds to a pretty formatted time string.
 * @param {number} seconds - Number of seconds
 * @returns - Pretty formatted time as a string.
 */
export function prettyTimeString(seconds) {
  if (seconds < 3600) {
    return `${~~(seconds / 60)}:${('00' + ~~(seconds % 60)).slice(-2)}`;
  } else {
    const hrs = ~~(seconds / 3600);
    const mins = ~~((seconds - ~~(seconds / 3600) * 3600) / 60);
    const secs = ~~(seconds % 60);
    return `${hrs}:${('00' + mins).slice(-2)}:${('00' + secs).slice(-2)}`;
  }
}

/**
 * Converts the pretty formatted time to number of seconds.
 * @param {string} pretty - The pretty formatted time.
 * @returns - Time in seconds as a number.
 */
export function convertPrettyTimeToSeconds(pretty) {
  return pretty
    .split(':')
    .reverse()
    .reduce((seconds, segment, index) => {
      return seconds + parseInt(segment) * Math.pow(60, index);
    }, 0);
}

/**
 * Validates a timestsamp input string.
 * @param {string} timestamp A timestamp string Ex: '0:00', '00:00', or '0:00:00'
 * @returns {boolean} True if valid, False if invalid
 */
export function validateTimeInput(timestamp) {
  const segments = timestamp.split(':');
  if (segments.length === 2) {
    return isNumeric(segments[0]) && isOneOrTwoDigits(segments[0]) && meetsAllCriteria(segments[1]);
  } else if (segments.length === 3) {
    return (
      isNumeric(segments[0]) &&
      isAtLeastOneDigit(segments[0]) &&
      meetsAllCriteria(segments[1]) &&
      meetsAllCriteria(segments[2])
    );
  } else {
    return false;
  }
}

/**
 * Checks a segment against all relevant validation criteria.
 * @param {string} segment A segment of a split timestamp string
 * @returns {boolean}
 */
export function meetsAllCriteria(segment) {
  return isNumeric(segment) && isTwoDigits(segment) && isLessThanSixty(segment);
}

/**
 * Determines is a segment is numeric.
 * @param {string} segment A segment of a split timestamp string
 * @returns {boolean}
 */
export function isNumeric(segment) {
  return !Number.isNaN(Number(segment));
}

/**
 * Determines if a segment is two digits.
 * @param {string} segment A segment of a split timestamp string
 * @returns {boolean}
 */
export function isTwoDigits(segment) {
  return segment.length === 2;
}

/**
 * Determines if a segment is one or two digits long.
 * @param {string} segment A segment of a split timestamp string
 * @returns {boolean}
 */
export function isOneOrTwoDigits(segment) {
  return segment.length === 2 || segment.length === 1;
}

/**
 * Determines if a segment is at least one digit long.
 * @param {string} segment A segment of a split timestamp string
 * @returns {boolean}
 */
export function isAtLeastOneDigit(segment) {
  return segment.length >= 1;
}

/**
 * Determines if a segment is less than 60.
 * @param {string} segment A segment of a split timestamp string
 * @returns {boolean}
 */
export function isLessThanSixty(segment) {
  return Number(segment) < 60;
}

/**
 * Converts Days, hours, minutes to hours with up to 2 decimal places.
 * @param {number} days Number of days
 * @param {number} hours Number of hours
 * @param {number} minutes Number of minutes
 * @returns {number}
 */
export function hoursDecimal(days, hours, minutes) {
  const combined = days * 24 + hours + minutes / 60;
  return Math.round(combined * 100) / 100;
}
