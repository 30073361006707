<template>
  <div class="draftCardLoading">
    <div class="firstLine">
      <div class="circle"></div>
      <span class="rect">Full Name Placeholder</span>
    </div>
    <div class="secondLine">
      <span class="rect">Last edited placeholder</span>
    </div>
    <div class="thirdLine">
      <span class="rect">Button placeholder</span>
    </div>
    <div class="topRight">
      <span class="circle small"></span>
      <span class="rect">IN PROGRESS</span>
    </div>
  </div>
</template>

<style lang="scss">
.draftCardLoading {
  height: 134px;
  background-color: var(--white);
  box-shadow: var(--elevation-low);
  padding: 32px 48px 40px 48px;
  border-radius: 6px;
  border: var(--border-subtle);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .firstLine {
    height: 42px;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  & .secondLine {
    height: 20px;
  }

  & .thirdLine {
    height: 24px;
  }

  & .topRight {
    position: absolute;
    top: 42px;
    right: 48px;
    height: 17px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & .circle {
    height: 42px;
    width: 42px;
    border-radius: 21px;
    background-color: #ddd;
    color: transparent;

    &.small {
      height: 12px;
      width: 12px;
      border-radius: 6px;
    }
  }

  & .rect {
    background: linear-gradient(to right, #ddd 20%, #eee 50%, #ddd 80%);
    animation: shiny-rect 4s linear infinite;
    color: transparent;
  }
}

@keyframes shiny-rect {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
</style>
