import { HttpService as http } from './HttpService';
import { URLS } from '@/resources';

/**
 * Class with static methods to interface with the CoachingMethod endpoint.
 */
export class CoachingMethodService {
  /**
   * Fetches all the available coaching methods to select for an assessment.
   * @param {boolean} includeInactive (default FALSE) Set to true to return all active and inactive coaching methods.
   * @returns {AxiosResponse} A list of available coaching methods for an assessment.
   */
  static fetchCoachingMethods(includeInactive = false) {
    return http.get(URLS.COACHING_METHOD, { params: { includeInactive } });
  }

  /**
   * Creates a new coaching method.
   * @param {Object} newCoachingMethod The new coaching method to create.
   * @returns {AxiosResponse}
   */
  static createCoachingMethod(newCoachingMethod) {
    return http.post(URLS.COACHING_METHOD, newCoachingMethod);
  }

  /**
   * Deactivates a coaching method.
   * @param {number} coachingMethodId The id of the coaching method to deactivate.
   * @returns {AxiosResponse}
   */
  static deactivateCoachingMethod(coachingMethodId) {
    return http.put(URLS.COACHING_METHOD, {}, { params: { coachingMethodId } });
  }
}
