import { defineStore } from 'pinia';
import { CoachingMethodService } from '@/services/CoachingMethodService';
import { BehaviorService } from '@/services/BehaviorService';
import { useAgentStore } from '@/stores';

export const useCoachingStore = defineStore('coaching', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    enterpriseFocuses: [],
    compendiumBehaviors: [],
    generalSkills: [],
    callOutcomes: [],
    loadingCoachingMethods: false,
    coachingMethods: []
  }),

  /*************************************************
   * GETTERS
   *************************************************/

  getters: {
    individualTargetFocusOptions: state => {
      return [
        { group: 'General Focus Area', options: state.generalSkills },
        { group: 'Compendium Behaviors', options: state.compendiumBehaviors }
      ];
    }
  },

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    /**
     * Fetches all available coaching methods and saves them in state.
     * @returns {Promise}
     */
    fetchCoachingMethods() {
      this.loadingCoachingMethods = true;
      return CoachingMethodService.fetchCoachingMethods()
        .then(res => {
          this.coachingMethods = res.data?.items;
        })
        .catch(error => {
          throw error;
        })
        .finally(() => {
          this.loadingCoachingMethods = false;
        });
    },
    /**
     * Fetches enterprise focuses and general skills and saves them to state.
     * @param {number} agentId The agent ID
     * @returns {Promise}
     */
    fetchFocusBehaviors(agentId) {
      const agentStore = useAgentStore();
      return BehaviorService.getFocusBehaviors(agentId, agentStore.selectedAgentSourceId, agentStore.selectedProgramId)
        .then(response => {
          const { siteFocusBehaviors, agentGeneralSkills } = response.data;
          this.enterpriseFocuses = siteFocusBehaviors;
          this.generalSkills = agentGeneralSkills;
        })
        .catch(error => {
          throw error?.response?.data || error?.message;
        });
    },
    /**
     * Fetches compendium behaviors for the selected program and saves them to state.
     * @returns {Promise}
     */
    fetchCompendiumBehaviors() {
      const agentStore = useAgentStore();
      return BehaviorService.getCompendiumBehaviors(agentStore.selectedProgramId)
        .then(response => {
          const compendiumBehaviors = response.data?.items;
          this.compendiumBehaviors = compendiumBehaviors;
          return compendiumBehaviors;
        })
        .catch(error => {
          throw error?.response?.data || error?.message;
        });
    },
    /**
     * Fetches call outcomes for the selected program and saves them to state.
     * @returns {Promise}
     */
    fetchCallOutcomes() {
      const agentStore = useAgentStore();
      return BehaviorService.getCallOutcomes(agentStore.selectedProgramId)
        .then(response => {
          this.callOutcomes = response.data?.items;
        })
        .catch(error => {
          throw error?.response?.data || error?.message;
        });
    },
    /**
     * Adds a new general skill.
     * @param {Object} skill The skill to add.
     */
    addNewGeneralSkill(skill) {
      this.generalSkills.push(skill);
    }
  }
});
