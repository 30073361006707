<template>
  <div v-if="deleted" class="evalLink--deleted" :title="deletedTooltip">
    <span v-if="!hideLabel">Evaluation ID: {{ evalId }}</span>
    <i class="icon small">not_interested</i>
  </div>
  <a v-else :href="link" class="evalLink" target="_blank">
    <span class="evalLink__label" v-if="!hideLabel">Evaluation ID: {{ evalId }}</span>
    <i class="icon small">open_in_new</i>
  </a>
</template>

<script>
import { QUALITYCARE_URL } from '@/application.config';

export default {
  name: 'EvaluationLink',
  data: () => ({
    deletedTooltip: 'This evaluation has been deleted and is not available to view'
  }),
  props: {
    evalId: Number,
    hideLabel: {
      type: Boolean,
      default: false
    },
    deleted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    link() {
      return `${QUALITYCARE_URL}/EvaluationWeb/Evaluate/Review/${this.evalId}`;
    }
  }
};
</script>

<style lang="scss">
.evalLink {
  display: flex;
  align-items: center;
  gap: 8px;

  &--deleted {
    @extend .evalLink;
    color: var(--black-trans);
    cursor: default;
  }
}
</style>
