<template>
  <div class="trendCard">
    <div class="trendCard__timeFrame">
      <slot name="timeFrame"></slot>
    </div>
    <div class="trendCard__metric">
      <slot name="metric"></slot>
    </div>
    <div class="trendCard__description">
      <slot name="description"></slot>
    </div>
  </div>
</template>

<style lang="scss">
.trendCard {
  background-color: var(--white);
  box-shadow: var(--elevation-low);
  padding: 32px 48px 40px 48px;
  border-radius: 6px;
  border: var(--border-subtle);
  display: flex;
  flex-direction: column;

  &__timeFrame {
    color: var(--black-trans);
    font-size: var(--font-size-small);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 24px;
  }

  &__metric {
    font-weight: var(--font-weight-bold);
    font-size: 32px;
    margin-bottom: 8px;
  }

  &__description {
    color: var(--black-trans);
  }
}
</style>
