<template>
  <div v-if="loadingSupervisors" class="dropdown__placeholder">
    <span>Loading...</span>
    <i class="icon">arrow_drop_down</i>
  </div>
  <TpgSelect
    v-else-if="supervisors"
    :modelValue="selectedSupervisor"
    @update:modelValue="updateSupervisor"
    :options="supervisors"
    :optionRenderFn="optionRenderFn"
    placeholder="Filter by supervisor"
  />
</template>

<script setup>
import { TpgSelect } from 'vue-components';
import { ref, inject, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useAgentStore } from '@/stores';

const notify = inject('notify').value;

const emit = defineEmits(['selectionUpdated']);

const { supervisors, loadingSupervisors } = storeToRefs(useAgentStore());
const agentStore = useAgentStore();

const selectedSupervisor = ref(null);

const updateSupervisor = selection => {
  emit('selectionUpdated', selection?.agentId || null);
  selectedSupervisor.value = selection;
};

const optionRenderFn = option => option && `${option.lastName}, ${option.firstName}`;

onMounted(() => {
  if (!supervisors.length) {
    agentStore.getSupervisors().catch(error => {
      notify.error(error);
    });
  }
});
</script>

<style lang="scss">
.dropdown__placeholder {
  position: relative;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  border: var(--sel-field-border);
  background-color: var(--white);
  color: #adadad;
  width: 300px;

  & span {
    margin-left: 16px;
  }

  & i.icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
