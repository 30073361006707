import { createRouter, createWebHistory } from 'vue-router';
import ManagerDash from '@/views/ManagerDash.vue';
import People from '@/views/People.vue';
import Assessment from '@/views/Assessment.vue';
import AgentSourceSelection from '@/views/AgentSourceSelection.vue';
import NotFound from '@/views/NotFound.vue';
import Agent from '@/views/Agent.vue';
import AssessmentDetail from '@/views/AssessmentDetail.vue';
import { authGuard, agentSourceGuard, adminGuard } from './guards';

const routes = [
  {
    path: '/',
    redirect: { name: 'ManagerDash' }
  },
  {
    path: '/dashboard',
    name: 'ManagerDash',
    component: ManagerDash,
    beforeEnter: [authGuard, agentSourceGuard]
  },
  {
    path: '/people',
    name: 'People',
    component: People,
    beforeEnter: [authGuard, agentSourceGuard]
  },
  {
    path: '/people/:agentId/assessment/perform',
    name: 'PerformAssessment',
    component: Assessment,
    beforeEnter: [authGuard, agentSourceGuard]
  },
  {
    path: '/people/:agentId/assessment/:coachingSessionId',
    name: 'AssessmentDetail',
    component: AssessmentDetail,
    beforeEnter: [authGuard, agentSourceGuard]
  },
  {
    path: '/people/:agentId',
    name: 'Agent',
    component: Agent,
    beforeEnter: [authGuard, agentSourceGuard]
  },
  {
    path: '/agentSourceSelection',
    name: 'AgentSourceSelection',
    component: AgentSourceSelection,
    beforeEnter: [authGuard]
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "group-admin" */ '@/views/Admin/Admin'),
    children: [
      {
        path: '',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "group-admin" */ '@/views/Admin/AdminHub'),
        beforeEnter: [authGuard, adminGuard]
      },
      {
        path: 'enterpriseFocusAdmin',
        name: 'EnterpriseFocusAdmin',
        component: () => import(/* webpackChunkName: "group-admin" */ '@/views/Admin/EnterpriseFocusAdmin'),
        beforeEnter: [authGuard, adminGuard]
      },
      {
        path: 'coachingMethodAdmin',
        name: 'CoachingMethodAdmin',
        component: () => import(/* webpackChunkName: "group-admin" */ '@/views/Admin/CoachingMethodAdmin'),
        beforeEnter: [authGuard, adminGuard]
      }
    ]
  },
  {
    path: '/insights',
    name: 'Insights',
    component: () => import(/* webpackChunkName: "insights" */ '@/views/Insights'),
    beforeEnter: [authGuard, agentSourceGuard]
  },
  {
    path: '/mydrafts',
    name: 'MyDrafts',
    component: () => import(/* webpackChunkName: "mydrafts" */ '@/views/MyDrafts'),
    beforeEnter: [authGuard, agentSourceGuard]
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    name: 'NotFound'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
