/**
 * Returns the full name of an agent.
 * @param {Object} agent Object which should contain firstName and lastName.
 * @returns {string} Agent's full name or ''
 */
export const fullName = agent => {
  return agent?.firstName && agent?.lastName
    ? `${agent.firstName.trim()} ${agent.lastName.trim()}`
    : agent?.firstName?.trim() || agent?.lastName?.trim() || '';
};

/**
 * Returns the full name of an agent.
 * @param {Object} agent Object which should contain firstName and lastName.
 * @returns {string} Agent's full name or ''
 */
export const lastFirst = agent => {
  return agent?.firstName && agent?.lastName
    ? `${agent.lastName.trim()}, ${agent.firstName.trim()}`
    : agent?.lastName?.trim() || agent?.firstName?.trim() || '';
};

/**
 * Returns the first and last initial of an agent's name.
 * @param {Object} agent Object which should contain firstName and lastName.
 * @returns {string} Agent's initials or ''
 */
export const initials = agent => {
  return agent?.firstName && agent?.lastName
    ? `${getInitial(agent.firstName)}${getInitial(agent.lastName)}`
    : getInitial(agent?.firstName) || getInitial(agent?.lastName) || '';
};

/**
 * Returns the intial letter of the given name
 * @param {string} name Name to retrieve intial letter for
 * @returns {string} First letter of the given name
 */
export const getInitial = name => {
  let initial;

  if (name && name.length) {
    initial = name.trim().charAt(0);
  }

  return initial;
};
