<template>
  <div class="step selectEvals">
    <div class="step__title">
      <div class="step__title--firstLine">Building an Assessment for</div>
      <section class="step__title--secondLine">
        <Avatar :agent="assessment.agent" :showName="true" />
        <TpgButton
          v-if="isEditing && canDeleteDraft"
          id="delete-draft-button"
          flat
          @click="onDeleteDraftClicked"
          title="Delete Draft"
          data-test="delete-draft-button"
        >
          <span class="material-icons">delete</span> Delete
        </TpgButton>
      </section>
    </div>
    <div class="step__subtitle">Attach up to 3 evaluations with media to get started</div>
    <SelectedEvalDisplay :selectedEvals="assessment.evaluations" @remove="toggleEval" />
    <div class="selectEvals__filters">
      <div class="selectEvals__appliedFilters">
        <strong>Filters applied:</strong>
        <span v-if="!appliedFilters.length">None</span>
        <template v-else>
          <AppliedFilterChip
            v-for="(filter, i) in appliedFilters.slice(0, 3)"
            :key="i"
            :filter="filter"
            @click="openFilterDialog"
          />
          <AppliedFilterChip
            v-if="appliedFilters.length > 3"
            :more="appliedFilters.length - 3"
            @click="openFilterDialog"
          />
        </template>
      </div>
      <TpgButton white class="selectEvals__filterButton" @click="openFilterDialog" data-cy="filter-dialog-button">
        <i class="icon">filter_list</i>Filter
      </TpgButton>
    </div>
    <div class="selectEvals__list">
      <EvalCardLoading v-if="loadingEvals || loadingAgentSources || loadingInteractions || loadingInteractionScore" />
      <template v-else>
        <template v-if="!!evaluations?.length">
          <EvalCard
            v-for="(e, i) of evaluations"
            :evaluation="e"
            :key="i"
            :selected="isEvalSelected(e.evaluationId)"
            @click="toggleEval(e)"
          />
          <SelectEvalsPagination />
        </template>
        <template v-else>
          <div class="selectEvals__noEvals">{{ noEvalsMessage }}</div>
        </template>
      </template>
    </div>

    <EvalFilterDialog ref="filterDialog" @applyFilters="setFiltersAndFetch" />

    <div class="step__actions">
      <TpgButton flat @click="$emit('back')"><i class="icon">arrow_back</i>Back</TpgButton>
      <div class="step__actions--right">
        <TpgButton flat @click="$emit('saveAsDraft')" data-cy="draft-button">Save as draft</TpgButton>
        <TpgButton @click="$emit('next')" data-cy="next-section-button">
          Next Section<i class="icon">arrow_forward</i>
        </TpgButton>
      </div>
    </div>
  </div>
</template>

<script>
import { TpgButton } from 'vue-components';
import EvalCard from './EvalCard.vue';
import EvalCardLoading from './EvalCardLoading.vue';
import EvalFilterDialog from './EvalFilterDialog.vue';
import AppliedFilterChip from './AppliedFilterChip.vue';
import SelectEvalsPagination from './SelectEvalsPagination.vue';
import SelectedEvalDisplay from './SelectedEvalDisplay.vue';
import Avatar from '@/components/Avatar.vue';
import { mapState, mapActions } from 'pinia';
import { DELETE_DRAFT_CONFIRMATION } from '@/resources';
import {
  useAssessmentStore,
  useEvalStore,
  useCoachingStore,
  useAgentStore,
  useInteractionStore,
  useConfirmDialogStore
} from '@/stores';
import { prettyDate } from '@/utils/date-helpers';

export default {
  name: 'SelectEvals',
  emits: ['next', 'back', 'saveAsDraft', 'delete'],
  components: {
    TpgButton,
    EvalCard,
    Avatar,
    EvalFilterDialog,
    AppliedFilterChip,
    SelectEvalsPagination,
    EvalCardLoading,
    SelectedEvalDisplay
  },
  inject: ['notify'],
  data: () => ({
    dateProps: {
      inputFormat: 'MM/dd/yyyy',
      // Only past dates can be selected since no completed evals will be available for future dates.
      startUpperLimit: new Date(),
      endUpperLimit: new Date(),
      canDeleteDraft: false
    },
    noEvalsMessage: 'There are no evaluations for this agent that match the selected filters.'
  }),
  props: {
    assessment: Object
  },
  computed: {
    ...mapState(useEvalStore, ['evaluations', 'loadingEvals', 'filters']),
    ...mapState(useCoachingStore, ['compendiumBehaviors', 'callOutcomes']),
    ...mapState(useAgentStore, ['agentSources', 'selectedProgram', 'loadingAgentSources']),
    ...mapState(useInteractionStore, ['loadingInteractions', 'loadingInteractionScore']),
    selectedEvalIds() {
      return this.assessment.evaluations.map(e => e.evaluationId);
    },
    isEditing() {
      return this.assessment.coachingSessionId;
    },
    appliedFilters() {
      if (this.filters) {
        let applied = [
          {
            type: 'Date Range',
            text: `${prettyDate(this.filters.startDate)} - ${prettyDate(this.filters.endDate)}`
          }
        ];
        if (this.filters.selectedPrograms && this.filters.selectedPrograms.length) {
          applied = [
            ...applied,
            ...this.filters.selectedPrograms.map(program => ({
              type: 'Program',
              behaviorText: program?.path
            }))
          ];
        }
        if (this.filters.selectedOutcome) {
          applied = [
            ...applied,
            {
              type: 'Call Outcome',
              behaviorId: this.filters.selectedOutcome.behaviorId,
              behaviorText: this.filters.selectedOutcome.behaviorText
            }
          ];
        }
        if (this.filters.behaviorSelections && this.filters.behaviorSelections.length) {
          applied = [
            ...applied,
            ...this.filters.behaviorSelections.map(({ behavior }) => ({
              type: 'Behavior',
              behaviorId: behavior.behaviorIds,
              behaviorText: behavior.behaviorText
            }))
          ];
        }
        return applied;
      } else {
        return [];
      }
    }
  },
  methods: {
    ...mapActions(useAssessmentStore, ['toggleEval', 'getCanDeleteDraftPermission']),
    ...mapActions(useEvalStore, ['getEvalsByAgent', 'applyFilters', 'initFilters']),
    ...mapActions(useCoachingStore, ['fetchCompendiumBehaviors', 'fetchCallOutcomes']),
    ...mapActions(useAgentStore, ['fetchAgentSources']),
    ...mapActions(useInteractionStore, ['getEvaluationByInteractionId', 'getInteractionById']),
    ...mapActions(useConfirmDialogStore, { draftDeleteConfirm: 'open' }),
    hydrateFilterOptions() {
      this.callOutcomes.length ? undefined : this.fetchCallOutcomes();
      this.compendiumBehaviors.length ? undefined : this.fetchCompendiumBehaviors();
      this.agentSources?.length ? undefined : this.fetchAgentSources();
    },
    openFilterDialog() {
      this.$refs.filterDialog.open({ ...this.filters });
    },
    isEvalSelected(evalId) {
      return !!this.assessment.evaluations.find(e => e.evaluationId === evalId);
    },
    initFetch() {
      this.getEvalsByAgent(this.assessment.agent.agentId).catch(error => {
        this.notify.error(error);
      });
    },
    async setFiltersAndFetch(filters) {
      try {
        await this.applyFilters(filters);
      } catch (error) {
        this.notify.error(error);
      }
    },
    async onDeleteDraftClicked() {
      const confirmed = await this.draftDeleteConfirm(DELETE_DRAFT_CONFIRMATION);
      if (confirmed) {
        this.$emit('delete');
      }
    }
  },
  async mounted() {
    let resolve = Promise.resolve();
    if (!this.agentSources) {
      resolve = this.fetchAgentSources();
    }
    resolve.then(async () => {
      this.initFilters();
      this.initFetch();
      this.hydrateFilterOptions();

      // Auto apply date filter from query parameters for 'Coach This Call'
      const startDate = this.$route?.query.startDate;
      const endDate = this.$route?.query.endDate;
      const programId = this.$route?.query.programId;

      if (startDate && endDate) {
        await this.setFiltersAndFetch({
          startDate: new Date(startDate),
          endDate: new Date(endDate)
        });
      }
      // Auto toggle evaluation from query parameter for 'Coach This Call'
      const interactionId = this.$route?.query.interactionId;
      if (interactionId) {
        try {
          const interactionResponse = await this.getInteractionById(interactionId);
          const evaluationResponse = await this.getEvaluationByInteractionId(interactionId);
          const interaction = interactionResponse?.data;
          const score = evaluationResponse?.data;

          if (score && interaction) {
            const evaluation = {
              agentId: this.assessment.agent.agentId,
              evaluationEndDateTime: score.evaluationDate,
              evaluationId: score.id,
              evaluationStartDateTime: score.evaluationDate,
              interactionId: interactionId,
              mediaDocumentId: interaction.documentID,
              mediaDocumentName: interaction.mediaName,
              programId: programId,
              recordingId: score.recordingId
            };
            this.toggleEval(evaluation);
          }
        } catch (error) {
          this.notify.error(error);
        }
      }
    });

    this.canDeleteDraft = await this.getCanDeleteDraftPermission();
  }
};
</script>

<style lang="scss">
.selectEvals {
  max-width: 800px !important;

  &__filters {
    display: grid;
    grid-template-columns: 1fr 92px;
    column-gap: 16px;
    align-items: center;
    margin-top: 40px;
  }

  &__appliedFilters {
    color: #485974;
    display: flex;
    gap: 8px;
    align-items: center;
    white-space: nowrap;
    font-size: var(--font-size-small);
  }

  &__filterChips {
    color: var(--black);
    border-radius: 4px;
    padding: 8px 16px;
    background-color: #bad5ff;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
  }

  &__searchInput {
    height: 41px !important;
    width: 50%;
  }

  &__filterButton {
    height: 41px;
  }

  &__list {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__noEvals {
    font-style: italic;
    text-align: center;
    padding: 40px;
  }
}

.step__title--secondLine {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .material-icons {
    color: var(--assess-text-accent-color);
  }
}
</style>
