import axios from 'axios';
import { API_URL } from '@/application.config';
import { useAuthStore } from '@/stores';

const baseURL = `${API_URL}/api`;

const defaultConfig = token => ({
  baseURL,
  withCredentials: false,
  headers: {
    Accepts: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
});

export class HttpService {
  static get(url, config) {
    return useAuthStore()
      .getTokens()
      .then(({ access_token }) => {
        if (access_token) {
          return axios.get(url, { ...defaultConfig(access_token), ...config });
        }
      });
  }
  static post(url, data, config) {
    return useAuthStore()
      .getTokens()
      .then(({ access_token }) => {
        if (access_token) {
          return axios.post(url, data, { ...defaultConfig(access_token), ...config });
        }
      });
  }
  static patch(url, data, config) {
    return useAuthStore()
      .getTokens()
      .then(({ access_token }) => {
        if (access_token) {
          return axios.patch(url, data, { ...defaultConfig(access_token), ...config });
        }
      });
  }
  static put(url, data, config) {
    return useAuthStore()
      .getTokens()
      .then(({ access_token }) => {
        if (access_token) {
          return axios.put(url, data, { ...defaultConfig(access_token), ...config });
        }
      });
  }
  static delete(url, config) {
    return useAuthStore()
      .getTokens()
      .then(({ access_token }) => {
        if (access_token) {
          return axios.delete(url, { ...defaultConfig(access_token), ...config });
        }
      });
  }
}
