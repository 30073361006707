<template>
  <BaseDialog v-bind="$attrs">
    <template v-slot:title>Add Outcomes</template>
    <template v-slot:content>
      <h5>Select desired outcomes to add to this assessment.</h5>
      <div class="addOutcomes__list">
        <OutcomeCard
          v-for="outcome in outcomes"
          :key="outcome.id"
          class="addOutcomes__card"
          :class="{ selected: selectedOutcomeIds[outcome.id] }"
          :outcome="outcome"
          @click="toggle(outcome.id)"
        />
      </div>
    </template>
    <template v-slot:actions>
      <TpgButton flat @click="$emit('cancel')">Close</TpgButton>
      <TpgButton @click="addOutcomes" :disabled="submitDisabled">Add Outcomes</TpgButton>
    </template>
  </BaseDialog>
</template>

<script>
import { BaseDialog, TpgButton } from 'vue-components';
import OutcomeCard from './OutcomeCard.vue';
import { mapState } from 'pinia';
import { useAssessmentStore, useCoachingStore } from '@/stores';

export default {
  name: 'AddOutcomeDialog',
  components: {
    BaseDialog,
    TpgButton,
    OutcomeCard
  },
  emits: ['cancel', 'addOutcomes'],
  data: () => ({
    selectedOutcomeIds: {}
  }),
  computed: {
    ...mapState(useAssessmentStore, ['activeAssessment']),
    ...mapState(useCoachingStore, { outcomeOptions: 'outcomes' }),
    outcomes() {
      const unavailable = this.activeAssessment.outcomeIds;
      return this.outcomeOptions.filter(o => !unavailable.includes(o.id));
    },
    submitDisabled() {
      return !this.selectedIdsArray.length;
    },
    selectedIdsArray() {
      return Object.keys(this.selectedOutcomeIds).filter(id => this.selectedOutcomeIds[id]);
    }
  },
  methods: {
    toggle(id) {
      this.selectedOutcomeIds[id] = !this.selectedOutcomeIds[id];
    },
    addOutcomes() {
      this.$emit('addOutcomes', this.selectedIdsArray);
      this.reset();
    },
    reset() {
      this.selectedOutcomeIds = {};
    }
  }
};
</script>

<style lang="scss">
.addOutcomes {
  &__list {
    margin: 24px 0;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 500px;
    max-height: 450px;
    overflow-y: scroll;
  }

  &__card {
    cursor: pointer;
  }
}
</style>
