<template>
  <div class="assessmentCard__wrapper" :class="statusColor" @click="goToAssessmentDetail">
    <div class="assessmentCard">
      <div class="assessmentCard__left">
        <div class="assessmentCard__nextStep">
          <div class="assessmentCard__nextStep--indicator"></div>
          <span>Completed</span>
        </div>
        <div class="assessmentCard__status" data-cy="agent-name">
          {{ assessment.agentFirstName }} {{ assessment.agentLastName }}
        </div>
        <div v-if="assessment.coachedDate" class="assessmentCard__date" data-cy="coached-date">
          {{ prettyDate(assessment.coachedDate) }}
        </div>
        <div v-else class="assessmentCard__noDate" data-cy="no-coached-date">No Date Found</div>
      </div>
      <div class="assessmentCard__right">
        <Avatar :agent="agent" />
      </div>
    </div>
    <div class="assessmentCard__colorBorder" data-cy="status-color"></div>
  </div>
</template>

<script>
import { prettyDate } from '@/utils/date-helpers';
import Avatar from '../Avatar.vue';

export default {
  name: 'AssessmentCard',
  components: {
    Avatar
  },
  data: () => ({
    prettyDate
  }),
  props: {
    assessment: Object
  },
  computed: {
    statusColor() {
      switch (this.assessment?.nextStep) {
        case 'Needs Review':
          return 'yellow';
        case 'Pending Completion':
          return 'green';
        default:
          return 'green';
      }
    },
    agent() {
      return this.assessment?.agentFirstName && this.assessment?.agentLastName
        ? { firstName: this.assessment.agentFirstName, lastName: this.assessment.agentLastName }
        : {};
    }
  },
  methods: {
    goToAssessmentDetail() {
      this.$router.push({
        name: 'AssessmentDetail',
        params: { agentId: this.assessment.agentId, coachingSessionId: this.assessment.coachingSessionId }
      });
    }
  }
};
</script>

<style lang="scss">
.assessmentCard__wrapper {
  box-shadow: var(--elevation-low);
  border-radius: 6px;
  position: relative;
  height: fit-content;
  cursor: pointer;
  transition: box-shadow 0.1s linear;

  &.green {
    & .assessmentCard__nextStep--indicator {
      background-color: var(--assessment-green);
    }

    & .assessmentCard__colorBorder {
      background-image: var(--assessment-green-gradient);
    }
  }

  &.yellow {
    & .assessmentCard__nextStep--indicator {
      background-color: var(--assessment-yellow);
    }

    .assessmentCard__colorBorder {
      background-image: var(--assessment-yellow-gradient);
    }
  }

  &:hover {
    box-shadow: var(--elevation-md);
    transition: box-shadow 0.1s linear;
  }
}

.assessmentCard {
  padding: 16px;
  border: var(--border-subtle);
  border-radius: 6px;
  position: relative;
  background-color: var(--white);
  z-index: 2;
  display: flex;
  justify-content: space-between;
  gap: 8px;

  &__colorBorder {
    height: 20px;
    margin-top: -14px;
    border-radius: 6px;
    position: relative;
    z-index: 1;
  }

  &__nextStep {
    &--indicator {
      height: 12px;
      width: 12px;
      min-width: 12px;
      border-radius: 6px;
    }

    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--black-trans);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-bold);
  }

  &__status {
    font-weight: var(--font-weight-bold);
    margin-top: 8px;
    margin-bottom: 10px;
  }

  &__date {
    font-size: var(--font-size-small);
    color: var(--black-trans);
  }

  &__noDate {
    font-size: var(--font-size-small);
    color: var(--transparent);
  }
}
</style>
