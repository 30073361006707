import { defineStore } from 'pinia';
import { BehaviorService } from '@/services/BehaviorService';

export const useFocusAdminStore = defineStore('focusAdmin', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    siteFocusLoading: false,
    mergedFocuses: []
  }),

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    /**
     * Gets and prepares all the info needed to use the Enterprise Focus Admin page. "Site Focus" term used for brevity.
     * @param {number} agentSourceId Agent source id of the selected program (from admin page)
     * @param {number} programId Program id of the selected program (from admin page)
     */
    async getSiteFocusAdminData(agentSourceId, programId) {
      this.siteFocusLoading = true;
      let result;
      try {
        const [siteFocuses, allBehaviors] = await Promise.all([
          this.getSiteFocuses(agentSourceId, programId),
          this.getAllBehaviors(programId)
        ]);
        this.mergeFocusAdminData(siteFocuses, allBehaviors);
      } catch (error) {
        result = error;
      }
      this.siteFocusLoading = false;
      return result;
    },
    /**
     * Gets Site Focuses for the program.
     * @param {number} agentSourceId Agent source id of the selected program (from admin page)
     * @param {number} programId Program id of the selected program (from admin page)
     * @returns {AxiosResponse}
     */
    async getSiteFocuses(agentSourceId, programId) {
      const siteFocusResponse = await BehaviorService.getSiteFocusBehaviors(agentSourceId, programId);
      return siteFocusResponse.data?.items;
    },
    /**
     * Gets all behaviros for a program.
     * @param {number} programId Program id of the selected program (from admin page)
     * @returns {AxiosResponse}
     */
    async getAllBehaviors(programId) {
      const allBehaviorsResponse = await BehaviorService.getCompendiumBehaviors(programId);
      return allBehaviorsResponse.data?.items;
    },
    /**
     * "Merges" site focuses and compendium behaviors by marking behaviors that match site focuses with selected: true.
     * @param {array} siteFocuses A list of site focuses
     * @param {array} allBehaviors All compendium behaviors for a program
     * @returns {array} The list of all behaviors with selected field for items matching siteFocuses
     */
    mergeFocusAdminData(siteFocuses, focusBehaviors) {
      for (const siteFocus of siteFocuses) {
        this.markFocusAsSelected(focusBehaviors, siteFocus);
      }
      this.mergedFocuses = focusBehaviors;
    },
    /**
     * Marks a single focus in the list as selected.
     * @param {array} focuses The whole list of focuses to modify.
     * @param {object} toSelect The individual focus that needs to marked as selected.
     */
    markFocusAsSelected(focuses, toSelect) {
      const match = focuses.find(focus => focus.compendiumBehaviorId === toSelect.compendiumBehaviorId);
      if (match) {
        match.selected = true;
        match.siteFocusBehaviorId = toSelect.siteFocusBehaviorId;
      }
    },
    /**
     * Adds a site focus to the specified program (included in site focus object).
     * @param {Object} siteFocus The site focus to add
     * @returns {AxiosResponse}
     */
    async addFocusToSite(siteFocus) {
      const result = await BehaviorService.addSiteFocusBehavior(siteFocus);
      this.markFocusAsSelected(this.mergedFocuses, result.data);
      return result;
    },
    /**
     * Deactivates a site focus for a program.
     * @param {number} siteFocusBehaviorId The id of the site focus to deactivate
     * @returns {AxiosResponse}
     */
    async removeFocusFromSite(siteFocusBehaviorId) {
      const result = await BehaviorService.removeSiteFocusBehavior(siteFocusBehaviorId);
      this.mergedFocuses.find(focus => focus.siteFocusBehaviorId === siteFocusBehaviorId).selected = false;
      return result;
    }
  }
});
