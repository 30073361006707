<template>
  <table class="tpgTable">
    <thead>
      <tr>
        <HeaderColumn
          id="testNameHeaderColumn"
          text="Name"
          :sortable="true"
          :activeSort="activeSorted('name')"
          @click="updateSortOrder('name')"
        />
        <HeaderColumn
          text="Last Assessment"
          :sortable="true"
          :activeSort="activeSorted('lastAssessed')"
          @click="updateSortOrder('lastAssessed')"
        />
        <HeaderColumn
          text="Next Assessment"
          :sortable="true"
          :activeSort="activeSorted('nextAssessed')"
          @click="updateSortOrder('nextAssessed')"
        />
        <HeaderColumn />
      </tr>
    </thead>
    <tbody>
      <template v-if="loadingAgents">
        <TpgTableLoading />
      </template>
      <template v-else>
        <template v-if="agents.length > 0">
          <tr class="tpgTable__row" v-for="(agent, i) of agents" :key="i" data-cy="agent-row">
            <td @click="$emit('viewAgent', agent.agentId)" data-cy="view-agent">
              <div class="tpgTable__rowName">
                <Avatar :agent="agent" :showName="true" :showLastNameFirst="true" size="xsmall" />
              </div>
            </td>
            <td @click="$emit('viewAgent', agent.agentId)" data-cy="coached-date">
              {{ agent.coachingSession ? prettyDate(agent.coachingSession.coachedDate) : 'Nothing to show yet' }}
            </td>
            <td @click="$emit('viewAgent', agent.agentId)" data-cy="next-scheduled">
              {{ agent.coachingSession ? prettyDate(agent.coachingSession.nextScheduledDate) : 'Not yet scheduled' }}
            </td>
            <td
              @click="$emit('startAssessment', agent.agentId)"
              data-cy="start-assessment"
              class="agentTable__newAssessment"
            >
              <div class="tpgTable__link"><span>New Assessment</span><i class="icon">arrow_forward</i></div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="tpgTable__empty">
            <td colspan="5">No results</td>
          </tr>
        </template>
      </template>
    </tbody>
    <tfoot>
      <AgentTablePagination :colspan="7" />
    </tfoot>
  </table>
</template>

<script>
import HeaderColumn from '../HeaderColumn.vue';
import AgentTablePagination from './AgentTablePagination.vue';
import TpgTableLoading from '../TpgTableLoading.vue';
import { mapActions, mapState } from 'pinia';
import { useAgentStore } from '@/stores';
import { prettyDate } from '@/utils/date-helpers';
import Avatar from '../Avatar.vue';

export default {
  name: 'AgentTable',
  components: {
    HeaderColumn,
    AgentTablePagination,
    TpgTableLoading,
    Avatar
  },
  emits: ['viewAgent', 'startAssessment'],
  inject: ['notify'],
  data: () => ({
    prettyDate
  }),
  computed: {
    ...mapState(useAgentStore, {
      loadingAgents: 'loadingAgents',
      agents: 'agents',
      tableState: 'agentTableState'
    })
  },
  mounted() {
    if (!this.agents.length || (this.agents.length && this.tableState.totalPages === 0)) {
      this.getAgents().catch(error => {
        this.notify.error(error);
      });
    }
  },
  methods: {
    ...mapActions(useAgentStore, ['getAgents', 'updateSortOrder']),
    activeSorted(column) {
      return column === this.tableState.sortOrder ? (this.tableState.asc ? 'asc' : 'desc') : 'none';
    }
  }
};
</script>

<style lang="scss">
.agentTable__newAssessment {
  width: 15%;

  &:hover i.icon {
    transform: scale(1.1);
    transition: 0.1s linear;
  }

  &:hover .tpgTable__link {
    gap: 12px;
    transition: 0.1s linear;
  }
}

.tpgTable__rowName .avatar__fullName {
  font-size: var(--font-size-small);
  color: var(--black);
  font-weight: var(--font-weight-bold);
}
</style>
