import { HttpService as http } from './HttpService';

/**
 * Class with static methods to assess interaction related API endpoints
 */
export class InteractionService {
  /**
   * Requests an interaction for the given interaction id
   * @param {number} interactionId Interaction id
   * @returns Interaction by given interaction id
   */
  static getInteractionById(interactionId) {
    return http.get(`interactions/${interactionId}`);
  }

  /**
   * Requests an evaluation score for the given interaction id
   * @param {number} interactionId Intearction id
   * @returns Evaluation score for the given interaction id
   */
  static getEvaluationByInteractionId(interactionId) {
    return http.get(`interactions/${interactionId}/score`);
  }

  /**
   * Get behaviors associated with the given program id
   */
  static getBehaviors(memberId, programIds) {
    const ids = programIds.join(',');
    return http.get(`/interaction/behaviors?programIds=${ids}&memberId=${memberId}`);
  }

  /**
   * Get option items associated with the given behavior
   */
  static getBehaviorOptionItems(memberId, programIds, behaviorId) {
    const ids = programIds.join(',');
    return http.get(`/interaction/behaviors/${behaviorId}/options?programIds=${ids}&memberId=${memberId}`);
  }
}
