<template>
  <CoachingTargetSelect
    v-model="selectedBehaviors"
    :options="behaviorOptions"
    @newGeneralSkill="$emit('newGeneralSkill', $event)"
  />
  <div v-for="(b, i) of selectedBehaviors" :key="i" class="afb__listItem" data-cy="selected-individual-focus">
    <div v-if="!b.behaviorId" class="afb__type" title="This is a general focus area"></div>
    <div v-else></div>
    <span>{{ b.behaviorText || b.description }}</span>
    <i class="icon" @click="unselect(b)">close</i>
  </div>
</template>

<script>
import CoachingTargetSelect from './CoachingTargetSelect.vue';

export default {
  name: 'AgentFocusBehaviors',
  components: {
    CoachingTargetSelect
  },
  props: {
    behaviorOptions: Array,
    modelValue: Array
  },
  emits: ['update:modelValue', 'newGeneralSkill'],
  computed: {
    selectedBehaviors: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  methods: {
    unselect(behavior) {
      this.selectedBehaviors = this.selectedBehaviors.filter(val => JSON.stringify(val) !== JSON.stringify(behavior));
    }
  }
};
</script>

<style lang="scss">
.afb__listItem {
  display: grid;
  grid-template-columns: 8px 1fr 24px;
  column-gap: 8px;
  align-items: center;
  min-height: 24px;
  margin-bottom: 8px;

  & i.icon {
    color: var(--assess-text-color);
    cursor: pointer;
  }
}

.afb__type {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: var(--assess-type-color);
}
</style>
