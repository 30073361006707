import { defineStore } from 'pinia';
import { AssessmentService } from '@/services/AssessmentService';
import { useAgentStore } from '@/stores';

export const useAgentCoachingHistoryStore = defineStore('agentCoachingHistory', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    loadingCoachingHistory: false,
    coachingHistory: [],
    listState: {
      agentId: null,
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
      hasPreviousPage: false,
      hasNextPage: false
    }
  }),

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    /**
     * Calls a service method to get coaching history for a given agent.
     * Saves coaching history and list params in state.
     * @param {number} agentId Agent ID.
     * @param {number} pageNumber The page number to request in the paginated response.
     * @param {number} pageSize The number of results to return for each page.
     * @returns An array of completed coaching sessions.
     */
    getAgentCoachingHistory(agentId, pageNumber, pageSize) {
      this.loadingCoachingHistory = true;
      const { selectedAgentSourceId, selectedProgramId } = useAgentStore();
      return AssessmentService.getCoachingHistoryByAgent(
        selectedAgentSourceId,
        selectedProgramId,
        agentId || this.listState.agentId,
        pageNumber || this.listState.currentPage,
        pageSize || this.listState.pageSize
      )
        .then(response => {
          const { items, pageIndex, totalPages, hasPreviousPage, hasNextPage } = response.data;
          this.coachingHistory = items;
          this.listState = {
            ...this.listState,
            currentPage: pageIndex,
            totalPages,
            hasPreviousPage,
            hasNextPage
          };
          agentId ? (this.listState.agentId = agentId) : undefined;
          pageSize ? (this.listState.pageSize = pageSize) : undefined;
          return items;
        })
        .catch(error => {
          throw error?.response?.data || error?.message;
        })
        .finally(() => {
          this.loadingCoachingHistory = false;
        });
    },
    /**
     * Updates the page size of the agent coaching history list and fetches results again.
     * @param {number} pageSize Number of results to return in the paginated list.
     * @returns An array of completed coaching sessions.
     */
    updatePageSize(pageSize) {
      return this.getAgentCoachingHistory(undefined, 1, pageSize);
    },
    /**
     * Gets the previous page of coaching history results.
     * @returns An array of completed coaching sessions.
     */
    getPreviousPage() {
      if (this.listState.hasPreviousPage) {
        return this.getAgentCoachingHistory(undefined, this.listState.currentPage - 1, undefined);
      }
    },
    /**
     * Gets the next page of coaching history results.
     * @returns An array of completed coaching sessions.
     */
    getNextPage() {
      if (this.listState.hasNextPage) {
        return this.getAgentCoachingHistory(undefined, this.listState.currentPage + 1, undefined);
      }
    },
    /**
     * Fetch a specific page of coaching history results.
     * @param {number} pageNumber The number of the page to fetch.
     * @returns An array of completed coaching sessions.
     */
    goToPage(pageNumber) {
      return this.getAgentCoachingHistory(undefined, pageNumber, undefined);
    }
  }
});
