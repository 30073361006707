import { HttpService as http } from './HttpService';
import { URLS } from '@/resources';

/**
 * Class with static methods to access trend related endpoints.
 */
export class TrendService {
  /**
   * Gets the total number of coaching sessions performed for this agent source.
   * @param {number} agentSourceId The selected agent source ID.
   * @param {number} programId The ID of the program to get data for.
   * @returns {AxiosResponse}
   */
  static getTotalSessionsTrend(agentSourceId, programId) {
    return http.get(URLS.TOTAL_SESSIONS, { params: { agentSourceId, programId } });
  }

  /**
   * Gets the total number of agents who have been coached this month.
   * @param {number} agentSourceId The selected agent source ID.
   * @param {number} programId The ID of the program to get data for.
   * @param {number} monthNumber The number of this month (1 - 12).
   * @param {number} yearNumber This year (YYYY).
   * @returns {AxiosResponse}
   */
  static getTotalAgentsCoachedTrend(agentSourceId, programId, monthNumber, yearNumber) {
    return http.get(URLS.TOTAL_AGENTS, { params: { agentSourceId, programId, monthNumber, yearNumber } });
  }

  /**
   * Gets the total time spent coaching this week for the given agent source.
   * @param {number} agentSourceId The selected agent source ID.
   * @param {number} programId The ID of the program to get data for.
   * @param {string} dateTime Today's date in ISO string format.
   * @returns {AxiosResponse}
   */
  static getTotalTimeCoachingTrend(agentSourceId, programId, dateTime) {
    return http.get(URLS.TOTAL_TIME, { params: { agentSourceId, programId, dateTime } });
  }

  /**
   * Gets the number of times each active coaching method was used in a program over the given date range.
   * @param {number} agentSourceId The selected agent source ID.
   * @param {number} programId The ID of the program to get data for.
   * @param {string} startDate Start date of query in ISO date format.
   * @param {string} endDate End date of query (today) in ISO date format.
   * @returns {AxiosResponse}
   */
  static getCoachingMethodUsage(agentSourceId, programId, endDate, startDate) {
    return http.get(URLS.COACHING_METHOD_USAGE, { params: { agentSourceId, programId, startDate, endDate } });
  }

  /**
   * Gets the number of coaching sessions performed by each supervisor.
   * @param {number} agentSourceId The selected agent source ID.
   * @param {number} programId The ID of the program to get data for.
   * @param {string} startDate Start date of query in ISO date format.
   * @param {string} endDate End date of query (today) in ISO date format.
   * @returns {AxiosResponse}
   */
  static getCoachingCompletedBy(agentSourceId, programId, endDate, startDate) {
    return http.get(URLS.COACHING_COMPLETED_BY, { params: { agentSourceId, programId, startDate, endDate } });
  }

  /**
   * Gets a breakdown of the behaviors that have been used in coaching sessions over the given time range.
   * @param {number} agentSourceId The selected agent source ID.
   * @param {number} programId The ID of the program to get data for.
   * @param {string} startDate Start date of query in ISO date format.
   * @param {string} endDate End date of query (today) in ISO date format.
   * @returns {AxiosResponse}
   */
  static getBehaviorsCoached(agentSourceId, programId, endDate, startDate) {
    return http.get(URLS.BEHAVIORS_COACHED, { params: { agentSourceId, programId, endDate, startDate } });
  }
}
