<template>
  <div class="page">
    <PageBlockLoader v-model="loadingAssessment" />
    <div v-if="!loadingAssessment" class="page assessmentDetail">
      <div class="assessmentDetail__back">
        <TpgButton flat @click="back"><i class="icon small">arrow_back</i>Back</TpgButton>
      </div>
      <Avatar :agent="agent" :showName="true" />
      <div class="assessmentDetail__completionDetails">
        <div>
          <i class="icon">calendar_today</i>
          <span>Completed on:</span>
          <span class="assessmentDetail__lowEmphasis">{{ prettyDate(coachingSession.coachedDate) }}</span>
        </div>
        <div>
          <span>Coach:</span>
          <span>{{ coachingSession.coachedBy }}</span>
        </div>
      </div>
      <div class="assessmentDetail__mediaEvals">
        <h3>Media & Evaluations</h3>
        <template v-if="coachingSession.coachingSessionEvaluations?.length">
          <div v-for="(e, i) in coachingSession.coachingSessionEvaluations" :key="i" class="assessmentDetail__eval">
            <EvaluationLink :evalId="e.evaluationId" :deleted="e.deleted" />
            <div class="assessmentDetail__evalDate">{{ prettyDate(e.evaluation.evaluationEndDateTime) }}</div>
            <div class="assessmentDetail__evalRecordingId">Recording ID: {{ e.recordingId }}</div>
          </div>
        </template>
        <div v-else class="assessmentDetail__noData"></div>
      </div>
      <div class="assessmentDetail__mediaComments">
        <h3>Media Related Comments</h3>
        <template v-if="sortedComments.length">
          <div v-for="(comment, i) in sortedComments" :key="i" class="assessmentDetail__comment">
            <div class="assessmentDetail__commentTime">
              <span>{{ comment.timeInMedia }}</span>
              <span v-if="!!comment.evaluationId" class="assessmentDetail__commentEvalId">
                {{ comment.evaluationId }}
              </span>
            </div>
            <div class="assessmentDetail__commentTitle">{{ comment.commentTitle || '[No Comment Title]' }}</div>
            <div class="assessmentDetail__lowEmphasis">{{ comment.description }}</div>
          </div>
        </template>
        <div v-else class="assessmentDetail__noData"></div>
      </div>
      <div class="assessmentDetail__behaviors">
        <h3>Behaviors</h3>
        <div class="assessmentDetail__focusColumns">
          <div class="assessmentDetail__focusColumns--column">
            <h4>Enterprise Coaching Focuses</h4>
            <template v-if="enterpriseFocuses.length">
              <div v-for="(ent, i) in enterpriseFocuses" :key="i" class="assessmentDetail__enterprise">
                {{ ent.description }}
              </div>
            </template>
            <div v-else class="assessmentDetail__noData"></div>
          </div>
          <div class="assessmentDetail__focusColumns--column">
            <h4>Individual Coaching Targets</h4>
            <template v-if="individualFocuses.length">
              <div v-for="(ind, i) of individualFocuses" :key="i" class="assessmentDetail__individual">
                <div
                  v-if="ind.isGeneralSkill"
                  class="assessmentDetail__indicator"
                  title="This is a general focus area"
                ></div>
                <span>{{ ind.description }}</span>
              </div>
            </template>
            <div v-else class="assessmentDetail__noData"></div>
          </div>
        </div>
      </div>
      <div class="assessmentDetail__general">
        <h3>Coaching Methods & General Comments</h3>
        <template v-if="coachingSession.coachingSessionMethods.length">
          <p
            v-for="(method, i) in coachingSession.coachingSessionMethods"
            :key="i"
            class="assessmentDetail__coachingMethod"
          >
            {{ method.coachingMethod.description }}
          </p>
        </template>
        <p v-else class="assessmentDetail__noData"></p>
        <div v-if="coachingSession.finalComment" class="assessmentDetail__finalComment">
          {{ coachingSession.finalComment }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TpgButton, PageBlockLoader } from 'vue-components';
import { mapActions } from 'pinia';
import { useAssessmentStore, useDocStore } from '@/stores';
import { prettyDate } from '@/utils/date-helpers';
import { convertPrettyTimeToSeconds } from '@/utils/time';
import { EvaluationLink, Avatar } from '@/components';

export default {
  name: 'AssessmentDetail',
  components: {
    TpgButton,
    PageBlockLoader,
    EvaluationLink,
    Avatar
  },
  inject: ['notify'],
  data: () => ({
    coachingSession: null,
    loadingAssessment: true,
    prettyDate
  }),
  computed: {
    agent() {
      return this.coachingSession?.agentFirstName && this.coachingSession?.agentLastName
        ? { firstName: this.coachingSession.agentFirstName, lastName: this.coachingSession.agentLastName }
        : {};
    },
    coachingSessionId() {
      return Number(this.$route.params.coachingSessionId);
    },
    enterpriseFocuses() {
      // Filter enterprise focuses, then map to a nice object to display.
      return this.coachingSession.coachingSessionBehaviors
        .filter(focus => !!focus.siteFocusBehaviorId)
        .map(focus => ({ description: focus.siteFocusBehavior.compendiumBehaviorText }));
    },
    individualFocuses() {
      // Filter agent focus and general skills, then map to a nice object to display.
      return this.coachingSession.coachingSessionBehaviors
        .filter(focus => !!focus.agentFocusBehaviorId || !!focus.generalSkillId)
        .map(focus => {
          const isGeneralSkill = !!focus.generalSkillId;
          return {
            isGeneralSkill,
            description: isGeneralSkill
              ? focus.generalSkill.description
              : focus.agentFocusBehavior.compendiumBehaviorText
          };
        });
    },
    sortedComments() {
      return [...this.coachingSession.coachingSessionComments].sort((a, b) => {
        return convertPrettyTimeToSeconds(a.timeInMedia) - convertPrettyTimeToSeconds(b.timeInMedia);
      });
    }
  },
  methods: {
    ...mapActions(useAssessmentStore, ['getAssessmentById']),
    ...mapActions(useDocStore, ['updateDocTitle']),
    async fetchCoachingSession() {
      this.loadingAssessment = true;
      try {
        this.coachingSession = await this.getAssessmentById(this.coachingSessionId);
      } catch (error) {
        this.notify.error(error);
      }
      this.loadingAssessment = false;
    },
    back() {
      this.$router.back();
    }
  },
  mounted() {
    this.updateDocTitle('Coaching Session Detail');
    this.fetchCoachingSession();
  }
};
</script>

<style lang="scss">
.assessmentDetail {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__completionDetails {
    font-weight: var(--font-weight-bold);

    & div {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 24px;
    }
  }

  &__eval {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  &__evalId {
    font-weight: var(--font-weight-bold);
  }

  &__evalRecordingId {
    font-size: var(--font-size-small);
    color: var(--black-trans-light);
  }

  &__noData {
    color: var(--black-trans-light);
    font-style: italic;
    margin-top: 16px;

    &::after {
      content: 'None for this assessment';
    }
  }

  &__comment {
    padding-bottom: 24px;
    padding-left: 16px;
    border-left: 2px dashed var(--primary-darker);
    margin-left: 4px;

    & div {
      margin-bottom: 8px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__commentTime {
    color: var(--accent);
  }

  &__commentEvalId {
    color: var(--black-trans);
    font-size: var(--font-size-small);

    &::before {
      content: ' - ';
    }
  }

  &__commentTitle {
    font-weight: var(--font-weight-bold);
  }

  &__lowEmphasis {
    color: var(--black-trans-light);
  }

  &__focusColumns {
    display: flex;
    gap: 56px;

    &--column {
      min-width: 250px;
      white-space: normal;
    }
  }

  &__enterprise,
  &__individual {
    margin-top: 16px;
    margin-bottom: 16px;
    color: var(--black-trans-light);
  }

  &__individual {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__indicator {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: var(--black-trans-light);
  }

  &__coachingMethod {
    font-weight: var(--font-weight-bold);
  }

  &__finalComment {
    background-color: var(--primary-darker);
    padding: 24px 16px;
    max-width: 600px;
  }
}
</style>
