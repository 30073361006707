<template>
  <div class="draftCard" v-if="!isLoading">
    <div class="draftCard__name">
      <Avatar :agent="agent" size="small" />
      <span>{{ agent.lastName }}, {{ agent.firstName }}</span>
    </div>
    <div>
      <span class="draftCard__date">Last edited: </span>
      <span class="draftCard__dateLabel">{{ prettyDate(draft.coachedDate) }}</span>
    </div>
    <section class="draftCard__editSection">
      <a class="draftCard__editLink" @click="edit">Continue editing <i class="icon">arrow_forward</i></a>
      <TpgButton v-if="canDeleteDraft" @click="onDeleteDraftClicked" title="Delete Draft">
        <span class="material-icons">delete</span> Delete
      </TpgButton>
    </section>
    <div class="draftCard__status">
      <div class="draftCard__indicator"></div>
      <span class="draftCard__statusText">In Progress</span>
    </div>
  </div>
  <DraftCardLoading v-else />
</template>

<script setup>
import { computed, inject, ref } from 'vue';
import Avatar from '../Avatar.vue';
import { prettyDate } from '@/utils/date-helpers';
import { useRouter } from 'vue-router';
import { TpgButton } from 'vue-components';
import { DELETE_DRAFT_CONFIRMATION } from '@/resources';
import { useConfirmDialogStore, useAssessmentStore } from '@/stores';
import DraftCardLoading from './DraftCardLoading.vue';

const router = useRouter();
const confirmDialogStore = useConfirmDialogStore();
const assessmentStore = useAssessmentStore();

const notify = inject('notify').value;
const isLoading = ref(false);

const props = defineProps({
  draft: Object,
  canDeleteDraft: Boolean
});

const emit = defineEmits(['delete']);

const agent = computed(() => ({
  firstName: props.draft?.agentFirstName || '',
  lastName: props.draft?.agentLastName || ''
}));

const edit = () => {
  router.push({
    name: 'PerformAssessment',
    params: { agentId: props.draft.agentId },
    query: { draftId: props.draft.coachingSessionId }
  });
};

const onDeleteDraftClicked = async () => {
  const confirmed = await confirmDialogStore.open(DELETE_DRAFT_CONFIRMATION);
  if (confirmed) {
    try {
      isLoading.value = true;
      await assessmentStore.deleteDraft(props.draft?.coachingSessionId);
      emit('delete');
    } catch (error) {
      notify.error(error.message);
    } finally {
      isLoading.value = false;
    }
  }
};
</script>

<style lang="scss">
.draftCard {
  position: relative;
  background-color: var(--white);
  box-shadow: var(--elevation-low);
  padding: 32px 48px 40px 48px;
  border-radius: 6px;
  border: var(--border-subtle);
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__name {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
  }

  &__date {
    color: var(--black-trans-light);
    font-weight: var(--font-weight-medium);
  }

  &__dateLabel {
    color: var(--black-trans);
    font-weight: var(--font-weight-bold);
  }

  &__editSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__editLink {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--accent);
    cursor: pointer;
    font-weight: var(--font-weight-medium);
  }

  &__status {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 8px;
    top: 42px;
    right: 48px;
  }

  &__indicator {
    height: 12px;
    width: 12px;
    min-width: 12px;
    border-radius: 6px;
    background-color: var(--assessment-yellow);
  }

  &__statusText {
    text-transform: uppercase;
    color: var(--black-trans);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-small);
    letter-spacing: 1px;
  }
}
</style>
