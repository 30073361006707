<template>
  <span class="evalButton">
    <span>Evaluation ID: {{ evaluation.evaluationId }} - {{ prettyDate(evaluation.evaluationEndDateTime) }}</span>
    <EvaluationLink
      class="evalButton__link"
      :evalId="evaluation.evaluationId"
      :hideLabel="true"
      :deleted="evaluation.deleted"
    />
  </span>
</template>

<script>
import { prettyDate } from '@/utils/date-helpers';
import { EvaluationLink } from '@/components';

export default {
  name: 'EvalButton',
  components: {
    EvaluationLink
  },
  data: () => ({
    prettyDate
  }),
  props: {
    evaluation: Object
  }
};
</script>

<style lang="scss">
.evalButton {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 18px;
  color: var(--assess-text-accent-color);
  background-color: var(--assess-field-bg-color);
  font-size: var(--font-size-small);
  padding: 8px 16px;

  &.selected {
    color: var(--white);
    background-color: var(--accent);
  }

  &__link {
    display: none;
    color: var(--white) !important;
  }
}

.selected .evalButton__link {
  display: flex;
}
</style>
