<template>
  <teleport :to="teleportTo">
    <div class="lava">
      <div class="blob"><div></div></div>
      <div class="blob"><div></div></div>
      <div class="blob"><div></div></div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'LavaLamp',
  props: {
    teleportTo: {
      type: String,
      default: 'main'
    }
  }
};
</script>

<style lang="scss">
.lava {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
}

.blob {
  position: absolute;

  & div {
    border-radius: 50%;
    opacity: 0.2;
    filter: blur(100px);
  }
}

.blob:nth-child(1) {
  top: 0;
  left: 0;
  animation: translate-one ease 55s infinite;

  & div {
    height: 50vh;
    width: 50vw;
    background-color: #f0cd83;
    animation: morph-one ease-in-out 40s infinite;
  }
}

.blob:nth-child(2) {
  top: 0;
  right: 0;
  animation: translate-two ease 43s infinite;

  & div {
    height: 35vh;
    width: 35vw;
    background-color: #a3ffc8;
    animation: morph-two ease-in-out 45s infinite;
  }
}

.blob:nth-child(3) {
  bottom: 0;
  animation: translate-three ease 32s infinite;

  & div {
    height: 30vh;
    width: 30vw;
    background-color: #bad589;
    animation: morph-three ease-in-out 48s infinite;
  }
}

@keyframes morph-one {
  0%,
  100% {
    transform: scaleX(1) scaleY(1.4);
  }

  25% {
    transform: scaleX(1.3) scaleY(0.9);
  }

  50% {
    transform: scaleX(0.75) scaleY(1.2);
  }

  75% {
    transform: scaleX(1.5) scaleY(1);
  }
}

@keyframes morph-two {
  0%,
  100% {
    transform: scaleX(1.5) scaleY(0.9);
  }

  25% {
    transform: scaleX(1.2) scaleY(1.1);
  }

  50% {
    transform: scaleX(0.9) scaleY(1.3);
  }

  75% {
    transform: scaleX(1.3) scaleY(1);
  }
}

@keyframes morph-three {
  0%,
  100% {
    transform: scaleX(1.2) scaleY(1.9);
  }

  25% {
    transform: scaleX(1) scaleY(1.4);
  }

  50% {
    transform: scaleX(1.2) scaleY(1);
  }

  75% {
    transform: scaleX(1.4) scaleY(1.7);
  }
}

@keyframes translate-one {
  0%,
  100% {
    transform: translate(35%, 50%);
  }

  25% {
    transform: translate(100%, 100%);
  }

  50% {
    transform: translate(75%, 50%);
  }

  75% {
    transform: translate(0, -10%);
  }
}

@keyframes translate-two {
  0%,
  100% {
    transform: translate(0, 25%);
  }

  25% {
    transform: translate(-5%, 100%);
  }

  50% {
    transform: translate(-7%, 55%);
  }

  75% {
    transform: translate(-3%, 0%);
  }
}

@keyframes translate-three {
  0%,
  100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(15%, -15%);
  }

  50% {
    transform: translate(55%, -15%);
  }

  75% {
    transform: translate(25%, -10%);
  }
}
</style>
