<template>
  <div class="checkbox" data-cy="checkbox-item">
    <div class="checkbox__field" :class="{ checked }" data-cy="checkbox-field">
      <i v-if="checked" class="icon" data-cy="checkbox-icon">check</i>
    </div>
    <label v-if="label" data-cy="checkbox-label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'Checkbox',
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss">
.checkbox {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;

  &__field {
    height: var(--icon-height);
    width: var(--icon-height);
    min-width: var(--icon-height);
    border-radius: 3px;
    background-color: var(--assess-field-bg-color);
    color: var(--assess-text-accent-color);
    cursor: pointer;
    user-select: none;
  }

  & label {
    flex: 1 0 auto;
    cursor: pointer;
    white-space: normal;
    max-width: calc(100% - var(--icon-height) - 8px);
    margin-top: 2px;
  }

  &[primary] .checkbox__field {
    background-color: var(--primary-dark);
    color: var(--accent);
    border: 1px solid #bad5ff;
  }
}
</style>
