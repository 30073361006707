<template>
  <div class="comment">
    <div class="comment__left">
      <div v-if="!last" class="comment__timeline"></div>
      <div class="comment__timeStamp">
        <div
          class="comment__indicator"
          :class="{ anna: comment.anna || comment.isDefect === true, nominal: comment.isDefect === false }"
        ></div>
        <span id="commentTimeInMedia">{{ comment.timeInMedia }}</span>
      </div>
      <div class="comment__evalId" :title="`Evaluation Id: ${comment.evaluationId}`">{{ comment.evaluationId }}</div>
    </div>
    <div class="comment__right">
      <div class="comment__type">{{ comment.commentTitle || '[No Comment Title]' }}</div>
      <div class="comment__text">{{ comment.description }}</div>
      <div v-if="comment.isDefect == undefined" class="comment__actions">
        <i class="icon comment__actions--edit" @click="$emit('edit')">edit</i>
        <i class="icon comment__actions--delete" @click="$emit('delete')">delete</i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'Comment',
  emits: ['edit', 'delete'],
  props: {
    comment: Object,
    last: Boolean
  }
};
</script>

<style lang="scss">
.comment {
  display: grid;
  grid-template-columns: 1fr 5fr;

  &:hover {
    & .comment__actions {
      opacity: 1;
      transition: 0.1s linear;
    }
  }

  &__left {
    position: relative;
  }

  &__timeline {
    position: absolute;
    top: 8px;
    bottom: -8px;
    left: 6px;
    border-left: 1px var(--comment-timeline-color) dashed;
    z-index: 2;
  }

  &__indicator {
    position: relative;
    z-index: 3;
    height: var(--comment-ind-size);
    width: var(--comment-ind-size);
    border-radius: var(--comment-ind-border-radius);
    background-color: var(--comment-ind-bg-color);
    border: var(--comment-ind-border);
    display: inline-block;
    margin-right: 4px;

    &.anna {
      background-color: var(--comment-anna-ind-bg-color);
      border: var(--comment-anna-ind-border);
    }

    &.nominal {
      background: rgba(109, 250, 241, 0.8);
      border: 1px solid var(--success);
    }
  }

  &__timeStamp span {
    font-weight: var(--font-weight-bold);
  }

  &__evalId {
    margin-left: 17px;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-small);
  }

  &__right {
    position: relative;
  }

  &__type {
    font-weight: var(--font-weight-bold);
    color: var(--assess-text-accent-color);
    margin-bottom: 8px;
  }

  &__text {
    padding-bottom: 40px;
  }

  &__actions {
    opacity: 0;
    position: absolute;
    right: 16px;
    top: 0;
    transition: opacity 0.1s linear;

    &--edit {
      color: var(--assess-text-accent-color);
      margin-right: 8px;
      cursor: pointer;
    }

    &--delete {
      color: var(--error);
      cursor: pointer;
    }
  }
}
</style>
