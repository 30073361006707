<template>
  <div class="evalCard" :class="{ selected: selected }">
    <div class="evalCard__info">
      <div class="evalCard__dateRow">
        <span class="evalCard__date">{{ prettyDate(evaluation.evaluationEndDateTime) }}</span>
        <span class="evalCard__time">{{ prettyTime(evaluation.evaluationEndDateTime) }}</span>
      </div>
      <EvaluationLink @click.stop :evalId="evaluation.evaluationId" :deleted="evaluation.deleted" />
      <div v-if="hasInternalMedia" class="evalCard__internalMedia" data-cy="internal-media-label">
        Internal Media Available
      </div>
      <div v-else class="evalCard__externalMedia" data-cy="external-media-label">External Media Only</div>
    </div>
    <TpgButton flat data-cy="select-button">{{ selected ? 'Deselect' : 'Select' }}</TpgButton>
  </div>
</template>

<script>
import { prettyDate, prettyTime } from '@/utils/date-helpers';
import { TpgButton } from 'vue-components';
import { EvaluationLink } from '@/components';

export default {
  name: 'EvalCard',
  components: {
    TpgButton,
    EvaluationLink
  },
  data: () => ({
    prettyDate,
    prettyTime
  }),
  props: {
    evaluation: Object,
    selected: Boolean
  },
  computed: {
    hasInternalMedia() {
      return !!this.evaluation.mediaDocumentId;
    }
  }
};
</script>

<style lang="scss">
.evalCard {
  padding: 16px 40px;
  border-radius: 4px;
  background-color: var(--white);
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  transition: all 0.1s linear;

  &.selected {
    background-color: rgba(255, 255, 255, 0.5);
    transition: all 0.1s linear;

    & div {
      opacity: 0.5;
      transition: all 0.1s linear;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__dateRow {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__date {
    font-weight: var(--font-weight-bold);
  }

  &__time {
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-small);
  }

  &__id {
    color: var(--black-trans);
  }

  &__internalMedia,
  &__externalMedia {
    font-size: var(--font-size-small);
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
  }

  &__internalMedia {
    border: 1px solid var(--accent);
    color: var(--accent);
  }

  &__externalMedia {
    border: 1px solid var(--gray-4);
    color: var(--black-trans-light);
  }
}
</style>
