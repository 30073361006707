<template>
  <Checkbox
    v-for="(focus, i) of options"
    :key="i"
    :checked="isSelected(focus.siteFocusBehaviorId)"
    @click="toggleFocus(focus.siteFocusBehaviorId)"
    :label="focus.behaviorText"
    data-cy="enterprise-focus-item"
  />
</template>

<script>
import Checkbox from '../../Checkbox.vue';

export default {
  name: 'EnterpriseCoachingFocusSelection',
  components: {
    Checkbox
  },
  props: {
    options: Array,
    modelValue: Array
  },
  emits: ['update:modelValue'],
  methods: {
    isSelected(id) {
      return this.modelValue.includes(id);
    },
    toggleFocus(id) {
      const clone = [...this.modelValue];
      const index = clone.indexOf(id);
      if (index > -1) {
        clone.splice(index, 1);
      } else {
        clone.push(id);
      }
      this.$emit('update:modelValue', clone);
    }
  }
};
</script>
