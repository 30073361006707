<template>
  <TrendCardLoading v-if="totalTimeCoachingLoading" />
  <TrendCard v-else>
    <template v-slot:timeFrame>This Week</template>
    <template v-slot:metric>{{ timeCoachingHours }} Hours</template>
    <template v-slot:description>Time spent coaching</template>
  </TrendCard>
</template>

<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useTrendStore } from '@/stores';
import TrendCard from './TrendCard.vue';
import TrendCardLoading from './TrendCardLoading.vue';

const { totalTimeCoaching, timeCoachingHours, totalTimeCoachingLoading } = storeToRefs(useTrendStore());
const { getTotalTimeCoaching } = useTrendStore();

onMounted(() => {
  if (!totalTimeCoaching.value) {
    getTotalTimeCoaching();
  }
});
</script>
