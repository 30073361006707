<template>
  <div class="page managerDash">
    <div class="page__programSelect">
      <span>Showing dashboard for:</span>
      <ProgramDropdownDataHarness />
    </div>
    <template v-if="selectedAgentSourceId">
      <RecentActivity />
      <Trends />
      <DashboardDrafts />
    </template>
    <template v-else>
      <div>
        <h2>No Program Selected</h2>
        <h5>Please select a program to see a dashboard.</h5>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useDocStore, useAgentStore } from '@/stores';
import { RecentActivity, Trends, ProgramDropdownDataHarness, DashboardDrafts } from '@/components';

export default {
  name: 'ManagerDash',
  components: {
    RecentActivity,
    Trends,
    ProgramDropdownDataHarness,
    DashboardDrafts
  },
  computed: {
    ...mapState(useAgentStore, ['selectedAgentSourceId'])
  },
  methods: {
    ...mapActions(useDocStore, ['updateDocTitle'])
  },
  mounted() {
    this.updateDocTitle('Manager Dashboard');
  }
};
</script>

<style lang="scss">
.managerDash {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
</style>
