<template>
  <TrendCardLoading v-if="totalSessionsLoading" />
  <TrendCard v-else>
    <template v-slot:timeFrame>All Time</template>
    <template v-slot:metric>{{ totalSessions?.trend }}</template>
    <template v-slot:description>Completed assessments</template>
  </TrendCard>
</template>

<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useTrendStore } from '@/stores';
import TrendCard from './TrendCard.vue';
import TrendCardLoading from './TrendCardLoading.vue';

const { totalSessions, totalSessionsLoading } = storeToRefs(useTrendStore());
const { getTotalSessions } = useTrendStore();

onMounted(() => {
  if (!totalSessions.value) {
    getTotalSessions();
  }
});
</script>
