<template>
  <div class="page" v-if="agent">
    <div class="agent__titleSection">
      <Avatar :agent="agent" size="large" />
      <div>
        <h1 class="agent__name">{{ agent.firstName }} {{ agent.lastName }}</h1>
        <div>
          <span class="agent__company"></span>
          <span class="agent__role">Agent</span>
        </div>
      </div>
    </div>
    <div class="agent__history">
      <AgentCoachingHistory :agentId="agent.agentId" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useAgentStore, useDocStore } from '@/stores';
import { initials } from '@/utils/name-helpers';
import AgentCoachingHistory from '@/components/AgentCoachingHistory/AgentCoachingHistory.vue';
import { Avatar } from '@/components';

export default {
  // eslint-disable-next-line
  name: 'Agent',
  components: {
    AgentCoachingHistory,
    Avatar
  },
  inject: ['notify'],
  data: () => ({
    initials,
    agent: null
  }),
  methods: {
    ...mapActions(useAgentStore, ['getAgentById']),
    ...mapActions(useDocStore, ['updateDocTitle'])
  },
  mounted() {
    this.updateDocTitle('Agent Detail');
    this.getAgentById(Number(this.$route.params.agentId))
      .then(agent => {
        this.agent = agent;
      })
      .catch(() => {
        this.notify.error('That agent could not be loaded.');
        this.$router.push({ name: 'People' });
      });
  }
};
</script>

<style lang="scss">
.agent {
  &__titleSection {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &__name {
    color: var(--agent-name-color);
    margin: 0 0 16px 0;
  }

  &__history {
    margin-top: 80px;
  }
}
</style>
