<template>
  <div class="step">
    <div class="step__title">
      <div class="step__title--firstLine">Building an Assessment for:</div>
      <section class="step__title--secondLine">
        <Avatar :agent="assessment.agent" :showName="true" />
        <TpgButton
          v-if="isEditing && canDeleteDraft"
          id="delete-draft-button"
          flat
          @click="onDeleteDraftClicked"
          title="Delete Draft"
          data-test="delete-draft-button"
        >
          <span class="material-icons">delete</span> Delete
        </TpgButton>
      </section>
    </div>
    <div class="step__subtitle">{{ noEvalsSelected ? noMediaSubtitle : withMediaSubtitle }}</div>

    <div v-if="!noEvalsSelected" class="step__player">
      <BehaviorsMedia
        :evals="assessment.evaluations"
        :media="media"
        :loadingMedia="loadingMedia"
        :comments="assessment.comments"
        :timestamps="timestamps"
        :activeEval="activeEval"
        @addCommentAtTimestamp="openNewCommentForm"
        @activateEval="onActiveEvalChanged"
      />
    </div>

    <div :class="{ behaviors__noEvals: noEvalsSelected }">
      <div class="step__heading">Behaviors and Focuses</div>
      <div class="behaviors__targets">
        <div class="behaviors__targets--column">
          <div class="step__subheading">
            <div>Enterprise Coaching Focus</div>
            <div class="allThatApply">(Select all that apply)</div>
          </div>
          <EnterpriseCoachingFocusSelection v-model="selectedEnterpriseFocuses" :options="enterpriseFocuses" />
        </div>

        <div class="behaviors__targets--column">
          <div class="step__subheading">Individual Coaching Focus</div>
          <AgentFocusBehaviors
            v-model="selectedIndividualFocuses"
            :behaviorOptions="individualTargetFocusOptions"
            @newGeneralSkill="addNewGeneralSkill"
          />
        </div>
      </div>
    </div>

    <template v-if="!noEvalsSelected">
      <div class="step__heading behaviors__commentsTitle">
        <span>Comments</span>
        <div class="behaviors__addComment" @click="openNewCommentForm" data-cy="add-comment-button">
          <i class="icon small">post_add</i>Add Comment
        </div>
      </div>
      <Comments
        v-if="filteredComments.length"
        class="behaviors__comments"
        :comments="filteredComments"
        @edit="openEditCommentForm"
        @delete="deleteComment"
      />
      <div v-else class="behaviors__noComments">
        <span>No comments added yet.</span>
      </div>
    </template>

    <CommentForm
      ref="commentForm"
      @commentSubmitted="addEvalComment"
      @commentUpdated="editEvalComment"
      :maxSeconds="maxCommentTime"
    />

    <div class="step__actions">
      <TpgButton flat @click="$emit('back')"><i class="icon">arrow_back</i>Back</TpgButton>
      <div class="step__actions--right">
        <TpgButton flat @click="$emit('saveAsDraft')" data-cy="draft-button">Save as draft</TpgButton>
        <TpgButton @click="$emit('next')" data-cy="next-section-button">
          Next Section<i class="icon">arrow_forward</i>
        </TpgButton>
      </div>
    </div>
  </div>
</template>

<script>
import { TpgButton } from 'vue-components';
import Avatar from '../../Avatar.vue';
import EnterpriseCoachingFocusSelection from './EnterpriseCoachingFocusSelection.vue';
import AgentFocusBehaviors from './AgentFocusBehaviors.vue';
import Comments from './Comments.vue';
import BehaviorsMedia from './BehaviorsMedia.vue';
import CommentForm from '../../CommentForm.vue';
import { DELETE_COMMENT_CONFIRMATION, DELETE_DRAFT_CONFIRMATION } from '@/resources';
import { mapActions, mapState } from 'pinia';
import { useAssessmentStore, useCoachingStore, useMediaStore, useConfirmDialogStore } from '@/stores';

export default {
  // eslint-disable-next-line
  name: 'Behaviors',
  emits: ['next', 'back', 'saveAsDraft', 'delete'],
  components: {
    TpgButton,
    Avatar,
    Comments,
    CommentForm,
    EnterpriseCoachingFocusSelection,
    AgentFocusBehaviors,
    BehaviorsMedia
  },
  props: {
    assessment: Object
  },
  data: () => ({
    withMediaSubtitle: 'Identify areas for improvement in the selected media',
    noMediaSubtitle: 'Select behaviors and focuses to continue',
    activeEval: null,
    canDeleteDraft: false
  }),
  computed: {
    ...mapState(useMediaStore, ['loadingMedia', 'media', 'timestamps']),
    ...mapState(useCoachingStore, ['enterpriseFocuses', 'individualTargetFocusOptions']),
    selectedEnterpriseFocuses: {
      get() {
        return this.assessment.enterpriseTargetIds;
      },
      set(ids) {
        this.updateEnterpriseTargetIds(ids);
      }
    },
    selectedIndividualFocuses: {
      get() {
        return [...this.assessment.generalSkills, ...this.assessment.agentTargets];
      },
      set(val) {
        this.updateIndividualFocuses(val);
      }
    },
    noEvalsSelected() {
      return this.assessment.evaluations.length === 0;
    },
    filteredComments() {
      const evaluationComments = this.assessment?.comments.filter(
        comment => comment.evaluationId === this.activeEval?.evaluationId
      );

      if (this.timestamps && this.timestamps.length) {
        return evaluationComments.concat(this.timestamps);
      }

      return evaluationComments;
    },
    maxCommentTime() {
      return this.activeEval && this.activeEval.mediaDocumentId && Object.keys(this.media).length
        ? this.media[this.activeEval.mediaDocumentId] && this.media[this.activeEval.mediaDocumentId].duration / 1000
        : null;
    },
    isEditing() {
      return this.assessment.coachingSessionId;
    }
  },
  methods: {
    ...mapActions(useAssessmentStore, [
      'updateEnterpriseTargetIds',
      'updateIndividualFocuses',
      'addEvalComment',
      'editEvalComment',
      'deleteEvalComment',
      'getCanDeleteDraftPermission'
    ]),
    ...mapActions(useCoachingStore, ['addNewGeneralSkill']),
    ...mapActions(useMediaStore, ['getMediaForEachEvaluation', 'getMediaTimestamps']),
    ...mapActions(useConfirmDialogStore, { commentDeleteConfirm: 'open', draftDeleteConfirm: 'open' }),
    openNewCommentForm(initComment) {
      initComment.evaluationId = initComment.evaluationId || this.activeEval.evaluationId;
      this.$refs.commentForm.newComment(initComment);
    },
    openEditCommentForm(comment) {
      this.$refs.commentForm.editComment(comment);
    },
    async deleteComment(comment) {
      const confirmed = await this.commentDeleteConfirm(DELETE_COMMENT_CONFIRMATION);
      if (confirmed) {
        this.deleteEvalComment(comment);
      }
    },
    onActiveEvalChanged(evaluation) {
      this.activeEval = evaluation;

      if (this.activeEval) {
        this.getMediaTimestamps(this.activeEval.interactionId);
      }
    },
    async onDeleteDraftClicked() {
      const confirmed = await this.draftDeleteConfirm(DELETE_DRAFT_CONFIRMATION);
      if (confirmed) {
        this.$emit('delete');
      }
    }
  },
  async mounted() {
    this.getMediaForEachEvaluation(this.assessment.evaluations);
    this.canDeleteDraft = await this.getCanDeleteDraftPermission();
  }
};
</script>

<style lang="scss">
.behaviors {
  &__noEvals {
    margin-bottom: 80px;
  }

  &__noMedia {
    position: relative;
    height: 146px;
    width: 100%;
  }

  &__noMediaText {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-large);
    color: var(--accent-dark);
    font-weight: var(--font-weight-bold);
  }

  &__evalButtons {
    display: flex;
    gap: 16px;
    margin-top: 24px;
    margin-bottom: 40px;

    &--loading {
      height: 33px;
      width: 250px;
      border-radius: 18px;
      background-color: var(--assess-field-bg-color);
    }
  }

  &__targets {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 80px;
    margin-bottom: 40px;

    &--column {
      flex-grow: 1;
    }
  }

  &__commentsTitle {
    display: flex;
    gap: 24px;
    align-items: flex-end;
  }

  &__addComment {
    color: var(--accent);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    padding-bottom: 3px;
  }

  &__comments {
    max-width: 50%;
  }

  &__noComments {
    font-size: var(--font-size-small);
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
}
</style>
