import { HttpService as http } from './HttpService';
import { URLS } from '@/resources';

/**
 * Class with static methods to access evaluation related API endpoints.
 */
export class EvaluationService {
  /**
   * Requests a filtered, paginated list of evaluations for the given agent and program.
   * @param {Object} body All params to filter on, including agentId and programId.
   * {
   *   behaviors: Behaviors[],
   *   callOutcomeIds: number[],
   *   agentSourceId: number,
   *   programId: number,
   *   agentId: number,
   *   startDateTime: string,
   *   endDateTime: string,
   *   pageNumber: number,
   *   pageSize: number
   * }
   * @returns {AxiosResponse} A filtered list of evaluations for the agent.
   */
  static filteredAgentEvaluations(body) {
    http.get('/interaction/behaviors/7392/options?programIds=22&memberId=20483');
    http.get('/interaction/behaviors?programIds=22&memberId=20483');
    return http.post(URLS.EVALUATION, body);
  }
}
