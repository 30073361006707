<template>
  <tr class="tpgTable__pagination">
    <td :colspan="colspan">
      <PaginationControls
        v-model:selectedPageSize="pageSize"
        v-bind="listState"
        @firstPage="requestPage(1)"
        @previousPage="previousPage"
        @nextPage="nextPage"
        @lastPage="requestPage(listState.totalPages)"
        @goToPage="requestPage"
      />
    </td>
  </tr>
</template>

<script>
import { PaginationControls } from 'vue-components';
import { mapState, mapActions } from 'pinia';
import { useAgentCoachingHistoryStore } from '@/stores';

export default {
  name: 'AgentCoachingHistoryPagination',
  components: {
    PaginationControls
  },
  inject: ['notify'],
  props: {
    colspan: Number
  },
  computed: {
    ...mapState(useAgentCoachingHistoryStore, ['listState']),
    pageSize: {
      get() {
        return this.listState.pageSize;
      },
      set(value) {
        this.updatePageSize(value).catch(error => {
          this.notify.error(error);
        });
      }
    }
  },
  methods: {
    ...mapActions(useAgentCoachingHistoryStore, ['updatePageSize', 'getPreviousPage', 'getNextPage', 'goToPage']),
    previousPage() {
      this.getPreviousPage().catch(error => {
        this.notify.error(error);
      });
    },
    nextPage() {
      this.getNextPage().catch(error => {
        this.notify.error(error);
      });
    },
    requestPage(pageNumber) {
      this.goToPage(pageNumber).catch(error => {
        this.notify.error(error);
      });
    }
  }
};
</script>

<style lang="scss">
.achPagination {
  display: flex;
}
</style>
