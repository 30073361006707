export const defaultPieChartOptions = {
  plugins: {
    datalabels: {
      color: '#FFFFFF',
      formatter: (value, context) => `${value}\n${Math.round((value / context.chart.getDatasetMeta(0).total) * 100)}%`
    },
    tooltip: {
      callbacks: {
        label: context => {
          return `${context.label}: ${context.formattedValue}, ${Math.round(
            (context.parsed / context.chart.getDatasetMeta(0).total) * 100
          )}%`;
        }
      }
    },
    legend: {
      display: true,
      position: 'bottom',
      align: 'start',
      labels: {
        padding: 16
      }
    }
  }
};

export const defaultBarChartOptions = {
  indexAxis: 'y',
  scales: {
    y: {
      beginAtZero: true
    }
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: context => {
          return context.formattedValue;
        }
      }
    },
    legend: {
      display: false
    }
  },
  aspectRatio: 3 / 1,
  maintainAspectRatio: false
};
