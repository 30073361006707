import { v4 as uuidv4 } from 'uuid';

export const crossDomainRequest = (iframeId, destinationUrl, method, value) => {
  return new Promise(resolve => {
    const requestId = uuidv4();
    window.addEventListener('message', messageHandler.bind(null, destinationUrl, requestId, resolve));
    document.getElementById(iframeId)?.contentWindow.postMessage({ method, value, requestId }, destinationUrl);
  });
};

const messageHandler = (sender, requestId, resolve, event) => {
  if (event.origin === sender && event.data.requestId === requestId) {
    resolve(event.data.value);
    window.removeEventListener('message', messageHandler);
  }
};
